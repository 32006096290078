import React, { useEffect, useCallback, useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useDispatch, useSelector } from "react-redux";
import { getBarGraphData } from "../../Redux/Actions/Thunk/barGraphThunk";
import { formDataAppend } from "../../Utils/helpers";
import { Spin, Tabs } from "antd";
import { setActiveTab } from "../../Redux/Actions/tabAction";

const StackedChart = (props) => {
  const { TabPane } = Tabs;

  const dispatch = useDispatch();

  const activeTabData = useSelector((state) => state.tabStack.activeTab);
  const {
    graph: {
      graph: {
        [props?.block?.block_id]: {
          data: barData = [],
          xlabels = [],
          tab_options: tab_options = [],
          format: formatData = [],
        } = {},
      },
      isLoading,
    } = {},
  } = useSelector((state) => state);

  useEffect(() => {
    const data = props?.all_input_data;
    const formData = formDataAppend(data);
    dispatch(getBarGraphData(formData, props));
  }, []);

  const handleTabChange = useCallback(
    (key) => {
      dispatch(setActiveTab(key));
      const data = props?.all_input_data;
      Object.assign(data, { view_mode: key });
      const formData = formDataAppend(data);
      dispatch(getBarGraphData(formData, props));
    },
    [dispatch, props]
  );

  const tabOptions = useMemo(() => tab_options, [tab_options]);

  const color_code_list = useMemo(
    () => barData.map(({ color_code }) => color_code),
    [barData]
  );

  const config = useMemo(
    () => JSON.parse(props?.block?.config),
    [props?.block?.config]
  );

  const stackedChartConfig = useMemo(
    () => ({
      chart: {
        type: "column",
        height: config?.settings?.chart?.height ?? "",
      },
      plotOptions: {
        column: {
          stacking: "percent",
        },
      },
      colors: color_code_list,
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      legend: {
        enabled: true,
        align: config?.legend?.align ?? "",
        verticalAlign: config?.legend?.verticalAlign ?? "",
        layout: config?.legend?.layout ?? "",
        symbolRadius: "1",
      },
      xAxis: { categories: xlabels },
      yAxis: {
        reversedStacks: false,
        min: 0,
        max: config?.settings?.yAxis?.max ?? null,
        title: {
          text: "",
        },
        labels: {
          format:
            JSON.parse(props?.block?.config)?.settings?.yAxis?.labels?.format ??
            window.currencySymbol + "{value}",
        },
      },
      tooltip: {
        headerFormat: "<b>{point.x}</b><br/>",
        pointFormat:
          "{series.name}: " +
          (formatData === "amount"
            ? window.currencySymbol + "{point.y:,.0f}"
            : formatData === "percentage"
            ? "{point.y:,.0f}%"
            : "{point.y:,.0f}") +
          "<br/>Total: " +
          (formatData === "amount"
            ? window.currencySymbol + "{point.stackTotal:,.0f}"
            : formatData === "percentage"
            ? "{point.stackTotal:,.0f}%"
            : "{point.stackTotal:,.0f}"),
      },
      series: barData,
    }),
    [color_code_list, barData, xlabels, formatData, config]
  );

  return (
    <>
      <Spin spinning={isLoading}>
        {tabOptions.length > 0 && (
          <div style={{ marginLeft: "65%" }}>
            <Tabs activeKey={activeTabData} onChange={handleTabChange}>
              {tabOptions.map((tab) => (
                <TabPane tab={tab.title} key={tab.key} />
              ))}
            </Tabs>
          </div>
        )}
        <HighchartsReact highcharts={Highcharts} options={stackedChartConfig} />
      </Spin>
    </>
  );
};

export default StackedChart;
