import React, { useEffect, useState, useCallback } from "react";
import {
  Table,
  Button,
  Row,
  Col,
  Switch,
  List,
  Badge,
  Tooltip,
  Popover,
  message,
  Spin,
} from "antd";
import { FaEye } from "react-icons/fa";
import { FaInfoCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountDetail,
  getAccountReports,
} from "../../Redux/Actions/Thunk/accountThunk";
import { Link, useParams } from "react-router-dom";

import AccountServiceSettings from "../../Pages/Accounts/AccountServiceSettings";
import UpdateFromSalesforce from "../../Components/Modals/UpdateFromSalesforce";
import UploadSearchTermData from "../../Components/Modals/UploadSearchTermData";
import PageTypeCategory from "../../Components/Modals/PageTypeCategory";
import UploadProductCatalog from "../../Components/Modals/UploadProductCatalog";
import CustomBreadcrumb from "../../Components/Common/CustomBreadcrumb";
import { FiGrid, FiUsers } from "react-icons/fi";
import { HiRefresh } from "react-icons/hi";
import API_BI from "../../Utils/axios_bi";
import Config from "../../Config";
import { ACTIONS_TYPES } from "../../Redux/Reducers/accountReducer";
import { getUserId } from "../../Utils/user";
import { Token } from "../../Utils/helpers/token";

const {
  ADMIN: { baseURL: data_source_url },
  BI_APP: { baseURL: BI_APP_URL },
  API_BI: { BASE_URL_FOR_BI_APP: base_url, baseURL: base_url_api },
} = Config;

function AccountDetail(props) {
  let { account_id } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  var budget_target_goal = queryParams.get("budget_target_goal");

  const [accountId, setAccountId] = useState("");
  const [ims, setIms] = useState("");
  const [services, setServices] = useState([]);

  const { accounts: { account_details, isLoading } = {} } = useSelector(
    (state) => state
  );

  const dispatch = useDispatch();

  // Fetch data
  useEffect(() => {
    setAccountId({ account_id });
    dispatch(getAccountDetail({ account_id }));
  }, []);

  useEffect(() => {
    // setAccountInfo(account_details.data.account);
    // setIms(account_details.data.ims);
  }, []);

  const pageLinks = [
    {
      key: "account_ds_config",
      name: "Data Source Configuration",
      redirect_url:
        `${data_source_url}data-sources/` +
        account_id +
        "?token=" +
        Token.encryptAuthParams(localStorage.getItem("token")),
    },
    {
      key: "account_advanced_settings",
      name: "Advanced Settings",
      redirect_url: "/accounts/advanced-setting/" + account_id,
    },
    {
      key: "account_contacts",
      name: "Contacts",
      redirect_url: "/accounts/contact/" + account_id,
    },
    {
      key: "account_monthly_email_report_logs",
      name: "Monthly Email Report Logs",
      redirect_url: "/accounts/email-report-logs/" + account_id,
    },
    {
      key: "account_notifications",
      name: "Notifications",
      redirect_url: "/accounts/notification/" + account_id,
    },
  ];

  const services_data = account_details?.data?.services;
  const gray_services = account_details?.data?.services[0].gray_services;
  const red_services = account_details?.data?.services[0].red_services;

  var grayServicesHolistic = "";
  var redServicesHolistic = "";

  if (gray_services != undefined && gray_services.length > 0) {
    grayServicesHolistic = (
      <>
        <p>Services Up for Renewal:</p>
        <ul>
          {gray_services.map((gray_service) => (
            <li>{gray_service}</li>
          ))}
        </ul>
      </>
    );
  }

  if (red_services != undefined && red_services.length > 0) {
    redServicesHolistic = (
      <>
        <p>Services Up for Renewal:</p>
        <ul>
          {red_services.map((red_service) => (
            <li>{red_service}</li>
          ))}
        </ul>
      </>
    );
  }

  const sendMonthlyEmailReport = useCallback((e) => {
    const user_id = getUserId();
    var params = {
      account_id: account_id,
      user_id: user_id,
    };
    dispatch({
      type: ACTIONS_TYPES.API_REQUEST_INIT,
    });
    API_BI.post(base_url_api + "api/v1/send-email-report", params).then(
      (response) => {
        if (response.status == 200) {
          message.success(response.data.message);
        } else {
          message.error(response.data.message);
        }
        dispatch({
          type: ACTIONS_TYPES.API_REQUEST_COMPLETE,
        });
      }
    );
  });

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text, record) => {
        return (
          <>
            {text == 1 ? <Badge color="#8dc340" /> : <Badge color="#dd4b39" />}
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <>
            <span>{text}</span>
            <span className="report-notification-wrapper">
              {record.notifications?.critical > 0 ? (
                <a
                  target="_blank"
                  href={`${base_url}reports/v2/view/insight_workflow_notification?customer=${record.notifications?.clientId}&customerName=${account_details?.data?.account?.name}&severity=Critical&status=Open&service=${record.notifications?.serviceId}`}
                >
                  <span className="noti-red">
                    {record.notifications?.critical}
                  </span>
                </a>
              ) : (
                ""
              )}
              {record.notifications?.medium > 0 ? (
                <a
                  target="_blank"
                  href={`${base_url}reports/v2/view/insight_workflow_notification?customer=${record.notifications?.clientId}&customerName=${account_details?.data?.account?.name}&severity=Warning&status=Open&service=${record.notifications?.serviceId}`}
                >
                  <span className="noti-orange">
                    {record.notifications?.medium}
                  </span>
                </a>
              ) : (
                ""
              )}
              {record.notifications?.low > 0 ? (
                <a
                  target="_blank"
                  href={`${base_url}reports/v2/view/insight_workflow_notification?customer=${record.notifications?.clientId}&customerName=${account_details?.data?.account?.name}&severity=Information&status=Open&service=${record.notifications?.serviceId}`}
                >
                  <span className="noti-blue">{record.notifications?.low}</span>
                </a>
              ) : (
                ""
              )}
            </span>
          </>
        );
      },
    },
    {
      title: "Reports",
      key: "reports",
      align: "center",
      render: (text, record) => {
        return (
          <>
            <div
              className={
                record.status === 0
                  ? "panel-action-button ad-passive"
                  : "panel-action-button"
              }
            >
              <Tooltip title="View Reports">
                <Link
                  className={record.status === 0 ? "ad-disabled" : ""}
                  to={{
                    pathname: `/account/reports/${account_id}/${record.id}`,
                    myCustomProps: record.id,
                  }}
                >
                  <FaEye />
                </Link>
              </Tooltip>
            </div>
          </>
        );
      },
    },
    {
      title: "Settings",
      key: "settings",
      align: "center",
      className: "accsetting-btn",
      render: (text, record) => {
        if (record.id == 1) {
          return "";
        }
        return (
          <>
            <AccountServiceSettings
              account_id={account_id}
              service_id={record.id}
              service_name={record.name}
              service_status={record.status}
              budget_target_goal={budget_target_goal ? budget_target_goal : ""}
            />
          </>
        );
      },
    },
    {
      title: (filters, sortOrder) => (
        <div className="table-tooltip">
          Up For Renewal
          <Tooltip
            overlayClassName="table-tooltip-content"
            title="A red icon indicates at least one service for a client is 45 days away from their renewal date. A gray icon indicates a service’s Term Length is 1 or 2 months, which mean this service is effectively always up for renewal."
          >
            <span>
              <FaInfoCircle />
            </span>
          </Tooltip>
        </div>
      ),
      key: "up_for_renewal",
      render: (_, record) => {
        return (
          <div className="renewal-button">
            {(record.id == 1 && grayServicesHolistic != "") ||
              (record.id > 1 && record.gray_services != null) ? (
              <Popover
                content={
                  record.id == 1 ? grayServicesHolistic : record.gray_services
                }
                overlayClassName="renewal-popover"
              >
                <HiRefresh />
              </Popover>
            ) : (
              ""
            )}

            {(record.id == 1 && redServicesHolistic != "") ||
              (record.id > 1 && record.red_services != null) ? (
              <Popover
                content={
                  record.id == 1 ? redServicesHolistic : record.red_services
                }
                overlayClassName="renewal-popover"
              >
                <HiRefresh color="#ff3032" />
              </Popover>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      title: (filters, sortOrder) => (
        <div className="table-tooltip table-tooltip-specialist">
          Specialist
          <Tooltip
            overlayClassName="table-tooltip-content"
            title="Secondary specialist is listed in parenthesis, if applicable."
          >
            <span>
              <FaInfoCircle />
            </span>
          </Tooltip>
        </div>
      ),
      key: "specialist",
      dataIndex: "specialist",
      align: "left",
    },
  ];

  const redirect = () => {
    dispatch(
      getAccountReports({
        account_id: account_id,
        service_id: 3,
      })
    );
  };

  const account_advance_setting =
    account_details?.data?.account_advance_setting;

  const getAccountStatus = (accountStatus) => {
    var accountStatusString = "";
    if (accountStatus == 1 || accountStatus == 2) {
      accountStatusString = "Current";
    } else if (accountStatus == 3) {
      accountStatusString = "Former";
    } else if (accountStatus == 4) {
      accountStatusString = "Prospect";
    } else if (accountStatus == 5) {
      accountStatusString = "Farming";
    } else if (accountStatus == 6) {
      accountStatusString = "Black Listed";
    }
    return accountStatusString;
  };

  const getAutomatedReportsValue = (automatedReports) => {
    if (!automatedReports || automatedReports == 2) {
      return "Automatic";
    } else if (automatedReports == 3) {
      return "Never";
    } else {
      return "Manual";
    }
  };
  const breadcrumbItems = [
    { text: "", link: "/", icon: FiGrid },
    { text: "Accounts", link: "/accounts", icon: FiUsers },
    {
      text: account_details?.data?.account?.name,
    },
  ];
  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <Spin spinning={isLoading}>
        <div className="panel-heading">
          <Row>
            <Col
              lg={{ span: 8 }}
              xs={{ span: 24 }}
              className="panel-heading-title"
            >
              <h2>Account - {account_details?.data?.account?.name}</h2>
            </Col>
            <Col
              lg={{ span: 24 }}
              xs={{ span: 24 }}
              className="panel-heading-buttons account-details-phb"
            >
              <UploadSearchTermData account_id={account_id} />
              <UpdateFromSalesforce account_id={account_id} />
              <Link
                to={{
                  pathname: `/reports/v2/views/monthly_email_report/${account_id}`,
                }}
                target="_blank"
                style={{ marginRight: "10px" }}
              >
                <Button>Preview Monthly Report</Button>
              </Link>
              {account_advance_setting &&
                account_advance_setting?.monthly_email_type == 3 ? (
                <Button disabled="true">Email Monthly Report</Button>
              ) : (
                <Button onClick={sendMonthlyEmailReport}>
                  Email Monthly Report
                </Button>
              )}
              <PageTypeCategory account_id={account_id} />
              <UploadProductCatalog account_id={account_id} />
            </Col>
          </Row>
        </div>
        <div className="panel-body-content">
          <Row className="account-details-upper">
            <Col lg={{ span: 9 }} xs={{ span: 24 }}>
              <div className="account-details-info">
                <ul>
                  <li key="account_status">
                    <span>Account Status</span>
                    <span>
                      {getAccountStatus(account_details?.data?.account?.status)}
                    </span>
                  </li>
                  <li key="account_name">
                    <span>Name</span>
                    <span>{account_details?.data?.account?.name}</span>
                  </li>
                  <li key="account_ims">
                    <span>EMS</span>
                    <span> {account_details?.data?.ims}</span>
                  </li>
                  <li key="account_business_unit">
                    <span>Business Unit</span>
                    <span> {account_details?.data?.account?.business_unit}</span>
                  </li>
                  <li key="account_website">
                    <span>Website</span>
                    <span>{account_details?.data?.account?.website}</span>
                  </li>
                  <li key="account_platform">
                    <span>Platform</span>
                    <span>{account_details?.data?.account?.platform}</span>
                  </li>
                  <li key="account_automated_reports">
                    <span>Automated Reports</span>
                    <span>
                      {getAutomatedReportsValue(
                        account_advance_setting?.monthly_email_type
                      )}
                    </span>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={{ span: 9 }} xs={{ span: 24 }}>
              <div className="account-details-info">
                <ul>
                  <li key="account_primary_tier">
                    <span>Primary Tier</span>
                    <span>{account_details?.data?.account?.primary_tier}</span>
                  </li>
                  <li key="account_service_level">
                    <span>Service Level</span>
                    <span>{account_details?.data?.account?.service_level}</span>
                  </li>
                  <li key="account_industry">
                    <span>Industry</span>
                    <span> {account_details?.data?.account?.industry}</span>
                  </li>
                  <li key="account_gross_margin">
                    <span>Gross Margin</span>
                    <span>{account_details?.data?.account?.gross_margin}</span>
                  </li>
                  <li key="account_kpi">
                    <span>KPI</span>
                    <span> {account_details?.data?.account?.kpi}</span>
                  </li>
                  <li key="account_creation_date">
                    <span>Account Creation Date</span>
                    <span>
                      {account_details?.data?.account?.account_created_on}
                    </span>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={{ span: 6 }} xs={{ span: 24 }}>
              <div className="account-details-buttons">
                <span>
                  Hide Notifications <Switch />
                </span>

                <List
                  dataSource={pageLinks}
                  renderItem={(item) => (
                    <li key={item.key}>
                      <a
                        target={
                          item.key == "account_ds_config" ? "_blank" : "_self"
                        }
                        href={item.redirect_url}
                      >
                        {item.name}
                      </a>
                    </li>
                  )}
                />
              </div>
            </Col>
          </Row>
          <Row className="account-details-lower">
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={services_data}
                pagination={{ pageSize: 15 }}
              />
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
}

export default AccountDetail;
