import React, { useEffect, useState } from "react";
import { Input, Tooltip, Table, Row, Col, Modal, Select } from "antd";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountReports,
  getContractedServices,
  getAccountDetail,
  getUserAccounts,
} from "../../Redux/Actions/Thunk/accountThunk";
import { Link, useParams } from "react-router-dom";
import CustomBreadcrumb from "../../Components/Common/CustomBreadcrumb";
import { FiGrid, FiUsers } from "react-icons/fi";
import { isCustomer, isNonManagedCustomer } from "../../Utils/user";

const { Search } = Input;
const { Option } = Select;

function AccountReport(props) {
  let [account_id, set_account_id] = useState(useParams()?.account_id);
  let [service_id, set_service_id] = useState("0");
  let [isAccountVisible, setIsAccountVisible] = useState("none");
  let serviceId = useParams()?.service_id;

  const {
    accounts: {
      isLoading,
      reports: { data: report_data = [] } = {},
      services: { data: services_data = [] } = {},
      user_accounts: { data: user_accounts_data = [] } = {},
      account_details: { account_name: name = [] } = {},
    } = {},
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (serviceId != undefined) {
      set_service_id(serviceId);
    }
    dispatch(
      getAccountReports({
        account_id: account_id,
        service_id: serviceId ? parseInt(serviceId) : 0,
      })
    );
    dispatch(getAccountDetail({ account_id: account_id }));
  }, []);
  useEffect(() => {
    dispatch(getContractedServices({ account_id: account_id }));
    dispatch(getUserAccounts({}));
  }, []);

  useEffect(() => {
    if (isCustomer()) {
      if (user_accounts_data?.length > 0) {
        set_account_id(user_accounts_data[0]?.id);
        dispatch(
          getAccountReports({
            account_id: account_id,
            service_id: 0,
          })
        );
        dispatch(getAccountDetail({ account_id: account_id }));
      }
      if (user_accounts_data?.length > 1) {
        setIsAccountVisible("block");
      }
    }
  }, []);
  useEffect(() => {
    if (user_accounts_data?.length > 1) {
      set_account_id(user_accounts_data[0]?.id);
      setIsAccountVisible("");
    }
  }, [user_accounts_data]);

  const serviceChangeHandler = (selectedValue) => {
    set_service_id(selectedValue);
    dispatch(
      getAccountReports({
        account_id: account_id,
        service_id: parseInt(selectedValue),
      })
    );
  };

  const accountChangeHandler = (selectedValue) => {
    set_account_id(selectedValue);
    set_service_id(0);
    dispatch(
      getAccountReports({
        account_id: selectedValue,
        service_id: 0,
      })
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "report_name",
      key: "1",
      sorter: true,
      render: (_, record) =>
        record?.report_key == "monthly_email_report" ? (
          <Link
            to={`/reports/v2/views/${record.report_key}/${record.account_id}`}
            view
          >
            {record.report_name}
          </Link>
        ) : (
          <Link
            to={`/reports/v2/view/${record.report_key}/${record.account_id}`}
            view
          >
            {record.report_name}
          </Link>
        ),
    },
    {
      title: "Category",
      dataIndex: "service_name",
      key: "2",
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "5",
      render: (_, record) => (
        <div className="panel-action-button">
          <Tooltip title="View Report">
            {record?.report_key === "monthly_email_report" ? (
              <Link
                to={`/reports/v2/views/${record.report_key}/${record.account_id}`}
                view
              >
                {" "}
                <FaEye />
              </Link>
            ) : (
              <Link
                to={`/reports/v2/view/${record.report_key}/${record.account_id}`}
                view
              >
                {" "}
                <FaEye />
              </Link>
            )}
          </Tooltip>
        </div>
      ),
    },
  ];
  const breadcrumbItems = [
    { text: "", link: "/", icon: FiGrid },
    { text: "Accounts", link: "/accounts", icon: FiUsers },
    { text: name },
  ];

  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <div className="panel-heading">
        <Row>
          <Col span={24} className="panel-heading-title">
            <h2>Reports and Dashboards - {name}</h2>
          </Col>
        </Row>
      </div>
      <div className="panel-body">
        <Row className="panel-body-input">
          <Col span={24} className="panel-body-input-left">
            <div className="panel-body-select">
              <Select
                name="account"
                showSearch
                mode="single"
                maxTagCount={1}
                placeholder="Select Account"
                optionFilterProp="children"
                onChange={accountChangeHandler}
                value={account_id}
                // onSearch={onSearchform}
                //defaultValue="All"
                // open={true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                style={
                  isCustomer() || isNonManagedCustomer()
                    ? { width: 250, display: isAccountVisible }
                    : { width: 250, display: "none" }
                }
              >
                {user_accounts_data?.map((data) => (
                  <Option value={data?.id} key={data?.id}>
                    {data?.name}
                  </Option>
                ))}
              </Select>
              &nbsp;
              <Select
                name="page_type"
                showSearch
                mode="single"
                maxTagCount={1}
                placeholder="Select Category"
                optionFilterProp="children"
                onChange={serviceChangeHandler}
                // onSearch={onSearchform}
                //defaultValue="All"
                // open={true}
                value={parseInt(service_id)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "250px" }}
              >
                <Option value={parseInt(0)} id="0">
                  All
                </Option>
                {services_data?.map((data) => (
                  <Option value={data?.service_id} key={data?.service_id}>
                    {data?.service_name}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>

        {/* {loading ? <Loader /> : ""} */}
        <Row className="panel-body-content">
          <Table
            columns={columns}
            loading={isLoading}
            key={(record) => record.primaryKey}
            dataSource={report_data}
            pagination={{ pageSize: 50 }}
          />
        </Row>
      </div>
    </>
  );
}

export default AccountReport;
