import React, { useEffect, useState, useCallback, useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useDispatch, useSelector } from "react-redux";
import { getRevenueRetentionData } from "../../Redux/Actions/Thunk/LineChartThunk";
import { formDataAppend } from "../../Utils/helpers";
import { Spin, Tabs } from "antd";
import { setActiveLineTab } from "../../Redux/Actions/tabAction";

const LineChart1 = (props) => {
  const config = JSON.parse(props?.block?.config);
  var zoomType = config?.zoomType ?? "";
  const { TabPane } = Tabs;
  const activeTabData = useSelector((state) => state.tabStack.activeLineTab);
  const newTabData = useSelector((state) => state.lineChartData.activeLineTab);
  const TabData =
    newTabData && newTabData[props?.block?.block_id] != undefined
      ? newTabData[props?.block?.block_id]
      : "Monthly";
  const {
    lineChartData: {
      lineChartData: {
        [props?.block?.block_id]: {
          [props?.block?.block_id]: {
            series_data: series_data = [],
            categories: categories = [],
            tab_options: tab_options = [],
            insight_data: insight = [],
          } = {},
          isLoading,
        } = {},
      },
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    // if (props?.length == 0) {
    dispatch(getRevenueRetentionData(formData, props));
    // }
  }, []);

  const tabOptions = useMemo(() => tab_options, [tab_options]);

  const handleTabChange = useCallback(
    (key) => {
      dispatch(setActiveLineTab(key, props?.block?.block_id));
      var data = props?.all_input_data;
      Object.assign(data, { view_mode: key });
      var formData = formDataAppend(data);
      dispatch(getRevenueRetentionData(formData, props));
    },
    [dispatch, props]
  );

  var chart = {
    chart: {
      type: "spline",
    },
    colors: [
      "#90ed7d",
      "#7cb5ec",
      "#F9CB7F",
      "#e68a5f",
      "#007ACC",
      "#33FF57",
      "#FF33E9",
      "#FFA500",
    ],
    title: {
      text: config.title != undefined || config.title != "" ? config.title : "",
    },
    subtitle: {
      text:
        config.subTitleText != undefined || config.subTitleText != ""
          ? config.subTitleText
          : "",
    },

    chart: {
      zoomType: zoomType,
      panning: 0,
      panKey: "",
      selectionMarkerFill: "none",
      resetZoomButton: {
        position: {
          x: 0,
          y: -40,
        },
      },
      borderWidth: "",
      borderColor: "",
      spacingBottom: 20,
      spacingTop: 20,
      height: "",
    },
    xAxis: {
      title: {
        text: "",
      },
      categories: categories,
    },
    yAxis: [
      {
        // tickAmount: 5,
        title: {
          text: "",
        },
        labels: {
          formatter: function () {
            if (config.yAxis_label === "$") {
              return window.currencySymbol + this.value.toLocaleString();
            } else if (config.yAxis_label === "%") {
              return this.value.toLocaleString() + "%";
            } else {
              return this.value.toLocaleString();
            }
          },
        },
      },
      {
        title: {
          text: "",
        },
        labels: {
          formatter: function () {
            return this.value + "";
          },
        },
        gridLineWidth: 0,
        opposite: true,
      },
    ],
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      reversed: true,
      enabled: 1,
    },
    tooltip: {
      formatter: function () {
        if (
          (this.series.name == "CLV" && config.yAxis_label === "$") ||
          this.series.name.includes("Cohort")
        ) {
          return (
            "<b>" +
            this.x +
            "</b><br />" +
            this.series.name +
            " : " +
            window.currencySymbol +
            this.y.toFixed(2)
          );
        } else if (config.yAxis_label === "$") {
          return (
            "<b>" +
            this.x +
            "</b><br />" +
            this.series.name +
            " : " +
            window.currencySymbol +
            this.y.toLocaleString()
          );
        } else if (config.yAxis_label === "%") {
          return (
            "<b>" +
            this.x +
            "</b><br />" +
            this.series.name +
            " : " +
            this.y.toLocaleString() +
            "%"
          );
        } else {
          return (
            "<b>" +
            this.x +
            "</b><br />" +
            this.series.name +
            " : " +
            this.y.toLocaleString()
          );
        }
      },
    },
    plotOptions: {
      series: {
        // pointStart: 2010
      },
    },
    series: series_data,
    credits: {
      enabled: false,
    },
    exporting: {
      showTable: false,
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
  };

  return (
    <>
      <Spin spinning={isLoading}>
        {tabOptions.length > 0 && (
          <div style={{ marginLeft: "53%" }}>
            <Tabs activeKey={TabData} onChange={handleTabChange}>
              {tabOptions.map((tab) => (
                <TabPane tab={tab.title} key={tab.key} />
              ))}
            </Tabs>
          </div>
        )}
        {props?.block?.block_key == "impact_goal_setting_chart" ? (
          <p>
            Note: Projections are calculated using the CPA for the selected date
            range and the average CLV for the given months since first order.
          </p>
        ) : (
          ""
        )}
        <HighchartsReact
          highcharts={Highcharts}
          options={chart}
        ></HighchartsReact>
        {insight != undefined && insight.length > 0 ? (
          <div className="insights">
            <h4>Insights</h4>
            <div dangerouslySetInnerHTML={{ __html: insight }} />
          </div>
        ) : (
          "-"
        )}
      </Spin>
    </>
  );
};

export default LineChart1;
