import { FormattedNumberCell } from "../../../Utils/helpers/formatting";

const GoogleAssetCoverage = (props) => {
  let record_data = props?.record;
  const asset_types = [
    "landscape_image",
    "square_image",
    "portrait_image",
    "landscape_logo",
    "square_logo",
    "video",
    "text",
    "other",
  ];

  let cellClass = "";
  if (asset_types.includes(props.column.dataIndex)) {
    if (props?.value === 0) {
      cellClass = "light-red-column";
    } else if (props?.value === 1) {
      cellClass = "light-yellow-column";
    } else if (props?.value > 1) {
      cellClass = "light-green-column";
    } else if (props?.value == "-") {
      cellClass = "";
    }
  }

  const hasArrayChildren = Array.isArray(record_data?.children);

  if (hasArrayChildren) {
    return (
      <span className={""}>
        <FormattedNumberCell value={props.value} format={props.column.format} />
      </span>
    );
  } else {
    return (
      <strong>
        <span className={cellClass}>
          <FormattedNumberCell
            value={props.value}
            format={props.column.format}
          />
        </span>
      </strong>
    );
  }
};

export default GoogleAssetCoverage;
