export const APP_NAME = "BI";
export const DEVELOPER_EMAIL = "santoshstha2010@gmail.com";

export const messages = {
  networkError: "You are facing a network error. Please try again or refresh.",
  default: "Something went wrong.",
};

// Permission Error
export const permissionErrorMessage = {
  title: "Unauthorized Access",
  messages: [
    "You don't have permission to view this page. Please contact us on",
    "if you think this is an error, else you can close this tab.",
  ],
};

// Protected Routes Path
export const protectedRoutesValue = {
  UNAUTHORIZED_ACCESS: "/unauthorized-access",
};

export const ApiEndpoints = {
  LANDING_PAGE: "v2/google_landing_page_performance_new/landing_page",
  SOURCE_MEDUIM: "v2/google_landing_page_performance_new/source_medium_filter",
  PAGE_TYPE: "v2/google_landing_page_performance_new/page_type_filter",
  TRENDS: "v2/ppc_performance/trend",
  KEY_METRICS: "v2/ppc_performance/key_metrics",
  TREND_BREAK_DOWN: "v2/ppc_performance/trend_breakdown",
  DEVICE_PERFORMANCE: "v2/ppc_performance/device_performance",
  BAR_GRAPH: "v2/google_landing_page_insights/ga_landing_pages_stack_bar1",
  COLUMN_CHART_SESSSION:
    "v2/google_landing_page_insights/ga_landing_pages_bar_sessions1",
  COLUMN_CHART_CONVERSIONS:
    "v2/google_landing_page_insights/ga_landing_pages_bar_conversions1",
  COLUMN_CHART_REVENUE:
    "v2/google_landing_page_insights/ga_landing_pages_bar_revenue1",
  REVENUE_RETENTION_DATA: "v2/service_retention/revenue_retention1",
};

// API Endpoints of Data Sources
export const DataSourcesApiEndPoints = {
  amazonSponsorAds: {
    getAmazonSponsorAds: "/api/v1/data-source/amazon-sponsor-ads",
    postAmazonSponsorAds: "/api/v1/data-source/amazon-sponsor-ads",
    updateAmazonSponsorModel: "/api/v1/data-source/amazon-sponsor-ads/model/",
  },
  amazonProfile: "/api/v1/data-source/amazon-profile/",
  amazonMarketingService: {
    getAmazonMarketingAds: "/api/v1/data-source/amazon-marketing-service/get",
    postAmazonMarketingAds: "/api/v1/data-source/amazon-marketing-service/",
  },
  modelForm: {
    getModelForm: "/api/v1/data-source/model-data/",
    postModelForm: "/api/v1/data-source/model-data/",
  },
  googleAds: {
    getAllGoogleAdsParam: "/api/v1/data-source/google-ads/param/get-all/",
    getGoogleAdsParam: "/api/v1/data-source/google-ads/param/get/",
    postGoogleAdsParam: "/api/v1/data-source/google-ads/param/",
    getGoogleAdsClient: "/api/v1/data-source/google-ads/client/get/",
    postGoogleAdsClient: "/api/v1/data-source/google-ads/client/",
    getGoogleAdsProfile: "/api/v1/data-source/google-ads/profile/",
    postGoogleAdsDateRange: "/api/v1/data-source/google-ads/pull-date-range/",
    getGoogleAdsDateRange:
      "/api/v1/data-source/google-ads/get-pull-date-range/",
  },
  dsResetHistoricalDataConfig: {
    getDsResetHistoricalDataConfigUrl:
      "/api/v1/data-source/reset-historical-data/",
  },
  amazonFullfilmentType: {
    getAmazonFullfilmentTypeUrl:
      "/api/v1/data-source/amazon-fullfillment-type/get/",
    postAmazonFullfilmentTypeUrl:
      "/api/v1/data-source/amazon-fullfillment-type/",
  },
  googleAnalyticsMCC: {
    getAllGoogleAnalyticsMCCUrl:
      "/api/v1/data-source/google-console-mcc/get-all/",
    getGoogleAnalyticsMCCUrl: "/api/v1/data-source/google-console-mcc/get/",
    postGoogleAnalyticsMCCUrl: "/api/v1/data-source/google-console-mcc/",
  },
  googleAnalyticsClient: {
    getGoogleAdsClientUrl: "/api/v1/data-source/google-analytics/get/",
    postGoogleAdsClientUrl: "/api/v1/data-source/google-analytics/",
    googleAdsClientUrl: "/api/v1/data-source/google-analytics/profile/",
  },
  amazonOrganicRating: {
    getAmazonOrganicRatingUrl: "/api/v1/data-source/amazon-organic-rating/get/",
    postAmazonOrganicRatingUrl: "/api/v1/data-source/amazon-organic-rating/",
  },
  amazonOrganicRanking: {
    getAmazonOrganicRankingUrl:
      "/api/v1/data-source/amazon-organic-ranking/get/",
    postAmazonOrganicRankingUrl: "/api/v1/data-source/amazon-organic-ranking/",
  },
  amazonSellingPartner: {
    getAmazonSellingPartner: "/api/v1/data-source/amazon-selling-partner/get/",
    postAmazonSellingPartner: "/api/v1/data-source/amazon-selling-partner/",
  },
  googleConsoleClient: {
    getGoogleConsoleClientUrl: "/api/v1/data-source/google-console-client/get/",
    postGoogleConsoleClientUrl: "/api/v1/data-source/google-console-client/",
    googleConsoleEC2URL: "/api/v1/data-source/google-console-client/profile/",
  },
  googleFeedProductStatus: {
    getGoogleFeedProductStatusUrl:
      "/api/v1/data-source/google-feed-product-statuses/get/",
    postGoogleFeedProductStatusUrl:
      "/api/v1/data-source/google-feed-product-statuses/",
    googleFeedProductStatusEC2URL:
      "/api/v1/data-source/google-feed-product-statuses/profile/",
  },
  googleSheets: {
    paidOppourtunityTracker: {
      getPaidOppourtunityTrackerUrl:
        "/api/v1/data-source/google-sheets/paid-opportunity-tracker/get/",
      postPaidOppourtunityTrackerUrl:
        "/api/v1/data-source/google-sheets/paid-opportunity-tracker/",
    },
    organicSearchOppourtunityTracker: {
      getOrganicSearchOppourtunityTracker:
        "/api/v1/data-source/google-sheets/organic-search-oppourtunity-tracker/get/",
      postOrganicSearchOppourtunityTracker:
        "/api/v1/data-source/google-sheets/organic-search-oppourtunity-tracker/",
    },
    emailMarketingGoogleSheet: {
      getEmailMarketingGoogleSheet:
        "/api/v1/data-source/google-sheets/email-marketing-google-sheet/get/",
      postEmailMarketingGoogleSheet:
        "/api/v1/data-source/google-sheets/email-marketing-google-sheet/",
    },
    organicSearchGoogleSheet: {
      getOrganicSearchGoogleSheet:
        "/api/v1/data-source/google-sheets/organic-search-google-sheet/get/",
      postOrganicSearchGoogleSheet:
        "/api/v1/data-source/google-sheets/organic-search-google-sheet/",
    },
  },
  pinterestMCC: {
    getAllPinterestMCCUrl: "/api/v1/data-source/pinterest-mcc/get-all/",
    getPinterestMCCUrl: "/api/v1/data-source/pinterest-mcc/get/",
    postPinterestMCCUrl: "/api/v1/data-source/pinterest-mcc/",
    getPinterestMCCSecretUrl: "/api/v1/get-pinterest-client-secret/",
  },
  pinterestClient: {
    getPinterestClientUrl: "/api/v1/data-source/pinterest-client/get/",
    postPinterestClientUrl: "/api/v1/data-source/pinterest-client/",
    pinterestClientProfileUrl: "/api/v1/data-source/pinterest-client/profile/",
  },
  tiktok: {
    getTiktokClientUrl: "/api/v1/data-source/tiktok/get/",
    postTiktokClientUrl: "/api/v1/data-source/tiktok/",
    tiktokCredentialsUrl: "/api/v1/data-source/tiktok/secrets/",
    tiktokProfileUrl: "/api/v1/data-source/tiktok/profile/",
  },
  AdWordsSearchAuctionInsightsUrl:
    "/api/v1/data-source/ads-shoping-auction-insights/",
  adWordsShoppingAuctionInsightsUrl:
    "/api/v1/data-source/google-ads-shopping-auction-insights/",
  AHREFsUrl: "/api/v1/data-source/ahrefs/",
  amazonBusinessReportsURl: "/api/v1/data-source/amazon-business-reports/",
  organicSearchUrl: "/api/v1/data-source/organic-search/",
  ScreamingFrogUrl: "/api/v1/data-source/screaming-frog/",
  BrightEdgeUrl: "/api/v1/data-source/brightedge/",
  bingAdsMCC: {
    getAllBingAdsMCCUrl: "/api/v1/data-source/bing-ads-mcc/get-all/",
    getBingAdsMCCUrl: "/api/v1/data-source/bing-ads-mcc/get/",
    postBingAdsMCCUrl: "/api/v1/data-source/bing-ads-mcc/",
  },
  bingAdsClient: {
    getBingAdsClientUrl: "/api/v1/data-source/bing-ads-client/get/",
    postBingAdsClientUrl: "/api/v1/data-source/bing-ads-client/",
    bingAdsClientProfileUrl: "/api/v1/data-source/bing-ads-client/profile/",
  },
  facebookAdsInsightsMCC: {
    getAllFacebookAdsInsightsMCCUrl:
      "/api/v1/data-source/facebook-ads-insights-mcc/get-all/",
    getFacebookAdsInsightsMCCUrl:
      "/api/v1/data-source/facebook-ads-insights-mcc/get/",
    postFacebookAdsInsightsMCCUrl:
      "/api/v1/data-source/facebook-ads-insights-mcc/",
    facebookCredentialsUrl:
      "/api/v1/data-source/facebook-ads-insights-mcc/secrets/",
    facebookTokenUrl: "/api/v1/data-source/facebook-ads-insights-mcc/token/",
  },
  facebookAdsInsightsClient: {
    getFacebookAdsInsightsClientUrl:
      "/api/v1/data-source/facebook-ads-insights-client/get/",
    postFacebookAdsInsightsClientUrl:
      "/api/v1/data-source/facebook-ads-insights-client/",
  },
};

export const ApiEndpointsBI = {
  LOGIN: "/api/v1/login",
  LOCATION_API: "https://geolocation-db.com/json/",
  //GET_MFA: process.env.REACT_APP_BI_API_URL+"api/v1/get-2fa",
  GET_MFA: "/api/v1/get-2fa",
  SAVE_MFA_TOKEN: "/api/v1/save-2fa-token",
  CHECK_MFA_TOKEN: "/api/v1/check-2fa-token",
  IMPERSONATE: "/api/v1/impersonate",
  USERS: "/api/v1/get-users",
  ROLES: "/api/v1/get-roles",
  GET_ACTIVE_REPORTS: "/api/v1/get-active-reports",
  CREATE_NEW_ROLE: "/api/v1/create-new-role",
  ROLESTATUSUPDATE: "/api/v1/update-role-status",
  ROLE_DETAILS: "/api/v1/get-role-details",
  ALL_ROLES: "/api/v1/get_all_roles",
  DELETE_ROLE: "/api/v1/delete_role",
  ACCOUNTS: "api/v1/get-accounts",
  ACCOUNT_DETAIL: "api/v1/account-detail",
  FREEMIUM_ACCOUNT_DETAIL: "api/v1/freemium-account-detail",
  ACCOUNT_ADVANCED_SETTING: "api/v1/account-advanced-setting",
  ACCOUNT_CONTACTS: "api/v1/account-contacts",
  ACCOUNT_EMAIL_REPORT_LOG: "api/v1/account-email-report-log",
  ACCOUNT_NOTIFICATIONS: "api/v1/account-notifications",
  ACCOUNT_FEED_TESTS: "api/v1/account-feed-tests",
  GET_IMS_USERS: "api/v1/get-ims",
  GET_SPECIALIST_USERS: "api/v1/get-specialists",
  GET_BUSINESS_UNITS: "api/v1/get-business-units",
  GET_SERVICES: "api/v1/get-services",
  GET_INTERNAL_REPORTS: "api/v1/internal-reports",
  GET_CONTRACTED_SERVICES: "api/v1/get-contracted-services",
  GET_USER_ACCOUNTS: "api/v1/get-user-accounts",
  GET_ACCOUNT_REPORTS: "api/v1/account-reports",
  GET_REPORTS_LAYOUTS: "api/v1/fetch-report-details",
  GET_CLIENT_ID: "api/v1/get-client-id",
  GET_ACCOUNT_DS: "api/v1/get-accounts-ds",
  GET_SINGLE_ACCOUNT_DS: "api/v1/get-single-account-ds/",
  SAVE_ACCOUNT_ADVANCED_SETTING: "api/v1/save-account-advanced-setting",
  ACCOUNT_WEBSITE: "api/v1/accounts-website/",
  SINGLE_ACCOUNT_DS: "api/v1/get-account-ds/",
  ACCOUNT_WEBSITE_DATA: "api/v1/get-all-account-website",
  ERROR_DS: "api/v1/update-error-message-account-ds",
  GET_AMAZON_SECRET: "api/v1/get-amazon-client-secret",
  GET_SERVICE_SETTINGS: "api/v1/get-service-settings",
  SAVE_SERVICE_SETTINGS: "api/v1/save-service-settings",
  GET_ACCOUNT_AND_REPORTS: "api/v1/reports-accounts-service",
  GET_USER_DETAIL: "api/v1/get-user-detail",
  CREATE_UPDATE_USER: "api/v1/save-user",
  TOGGLE_USER_STATUS: "api/v1/toggle-user-status",
  DELETE_USER: "api/v1/delete-user",
  TOGGLE_ROLE_STATUS: "api/v1/update-role-status",
  CHANGE_ROLE_TYPE: "api/v1/change-role-type",
  DEFAULT_DASHBOARD_LIST: "api/v1/default-dashboard-list",
  SET_PROFILE_DASHBOARD: "api/v1/update-profile-dashboard",
  SET_DEFAULT_DASHBOARD: "api/v1/update-default-dashboard",
  SAVE_FEEDBACK: "api/v1/save-feedback",
  SAVE_ALERT_VIEW_LOG: "api/v1/save-alert-view-log",
  SET_REPORT_STATUS: "api/v1/attach-detach-report",
  UPDATE_SALESFORCE_PERMISSION: "api/v1/update-salesforce-permission",
  GET_ACTIVE_USERS: "/api/v1/search-active-users",
  ADD_USERS_ROLE: "/api/v1/add-role-users",
  GET_FREEMIUM_SERVICES: "api/v1/get-freemium-services",
  GET_FREEMIUM_ACCOUNTS: "api/v1/get-freemium-accounts",
  GET_FREEMIUM_ACCOUNT: "api/v1/get-freemium-account",
  GET_AD_SPEND: "/api/v1/get_ad_spend",
  GET_FACEBOOK_API: "/api/v1/facebook",
  GET_TIKTOK_API: "/api/v1/tiktok",
  GET_ANNOTATIONS_COMMENT : "/api/v1/view-annotations",
  GET_ANNOTATIONS_REPLY: "/api/v1/view-reply",
  SAVE_ANNOTATIONS_COMMENT: "/api/v1/save-annotations",
  UPDATE_ANNOTATIONS_COMMENT: "/api/v1/update-annotations",
  DELETE_ANNOTATIONS_COMMENT: "/api/v1/delete-annotations",
  UPDATE_ANNOTATIONS_REPLY: "/api/v1/update-reply",
  DELETE_ANNOTATIONS_REPLY: "/api/v1/delete-reply",
  SAVE_ANNOTATIONS_REPLY: "/api/v1/save-reply",
  SAVE_ANNOTATIONS_COMMENT_SETTINGS: "/api/v1/save-comment-settings",
  SAVE_ANNOTATIONS_TAG: "/api/v1/save-tag",
  GET_ANNOTATIONS_TAG: "/api/v1/view-tag",
  GET_MENTION_USERS: "/api/v1/mention-users",
  FREEMIUM_UPLOAD: "/api/v1/push_adwords_data_to_sf",
  SAVE_NOTIFICATION_LOG: "/api/v1/save-email-notification-log",
  ACCOUNT_FEEDS_FETCH: "/api/v1/fetch-url-feeds"
};
