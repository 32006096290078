import $ from "jquery";
import isEqual from "lodash/isEqual";
import HtmlParser from "html-react-parser";
import { FiColumns, FiFilter } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  FaDownload,
  FaFileCsv,
  FaFileExcel,
  FaInfoCircle,
} from "react-icons/fa";
import {
  PlusOutlined,
  MinusOutlined,
  FilterOutlined,
  FilterFilled,
} from "@ant-design/icons";
import {
  Form,
  Menu,
  List,
  Input,
  Modal,
  Table,
  Select,
  Button,
  Tooltip,
  Checkbox,
  Dropdown,
  notification,
} from "antd";

import config from "../../Config";
import LPPModal from "../../Components/Modals/LPPModal";
import { checkFormat } from "../../Utils/checkTableFormat";
import { arr_diff, formDataAppend } from "../../Utils/helpers";
import { tableCellHelper } from "../../Utils/helpers/tableHelper";
import SpinComponent from "../../Components/Common/SpinComponent";
import { FormattedNumberCell } from "../../Utils/helpers/formatting";
import { getDataTable } from "../../Redux/Actions/Thunk/dataTableThunk";
import { getAllAccounts } from "../../Redux/Actions/Thunk/accountThunk";
import CustomColumnFilter from "./CustomTableComponents/CustomColumnFilter";
import {
  componentMatchReport,
  exportData,
} from "../../Utils/helpers/tableHelper";
import CustomTableBottomComponents from "./CustomTableComponents/CustomTableBottomComponent";
import CustomBtnSwitchTabComponents from "./CustomTableComponents/CustomBtnSwitchTabComponents";
import { setActiveTable } from "../../Redux/Actions/tabAction";
const {
  API_BI: { baseURL: baseUrl_API },
} = config;

const DataTable = React.memo((props) => {
  const previousValue = useRef(null);
  const [getTitle, setTitle] = useState([]);
  const [getFilter, setFilter] = useState({});
  const [getWebUrl, setWebUrl] = useState([]);
  const [abTest, setAbTest] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [getSortData, setSortData] = useState({});
  const [searchText, setSearchText] = useState("");
  const [seriesData, setSeriesData] = useState([]);
  const [getAllColumn, setAllColumn] = useState([]);
  const [accountName, setAccountName] = useState([]);
  const [getAdsColumns, setAdsColumns] = useState([]);
  const [getAbsColumns, setAbsColumns] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHiddenColumns, setHiddenColumns] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [rowKeySelected, setRowKeySelected] = useState([]);
  const [getChangeColumns, setChangeColumns] = useState([]);
  const [getOnlyChangeCol, setOnlyChangeCol] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [getAnalyticsColumns, setAnalyticsColumns] = useState([]);
  const [isShowHideModalOpen, setIsShowHideModalOpen] = useState(false);
  const [isSharePopoverVisible, setIsSharePopoverVisible] = useState(false);
  const [getKey, setKey] = useState({
    absoluteColumns: false,
    changeColumns: false,
  });

  const handleInfoClick = (e) => {
    e.preventDefault();
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const config = JSON.parse(props?.block?.config);

  const limit = config["limit"];
  const defaultOrder = config["defaultOrder"];
  const defaultOrderColumn = config["defaultOrderColumn"];

  const name = config["name"];
  const paginate = config["paginate"];
  const [format, setFormat] = useState("");
  const [totalRow, setTotalRow] = useState({});
  const defaultExpand = config["defaultExpand"];
  const [filterValue, setFilterValue] = useState("");
  const [activeTab, setActiveTab] = useState("Brand");
  const [hasClicked, setHasClicked] = useState(false);
  const [expandedAll, setExpandedAll] = useState(false);
  const [filterValueInt, setFilterValueInt] = useState("");
  const checkBoxRowSelection = config["selectRowSelection"];
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [filterCondition, setFilterCondition] = useState("equal");
  const [columnShowHideModal, setColumnShowHideModal] = useState([]);
  const [filterConditionInt, setFilterConditionInt] = useState("equal");
  const [emailSmsData, setEmailSmsData] = useState(0);
  const activeToggleData = useSelector((state) => state.tabStack.emailSms);

  if (config.hasOwnProperty("showAbsBtn")) {
    var showAbsBtn = config["showAbsBtn"];
    var absolute_change_columns = config["absolute_change_columns"];
  }
  if (config.hasOwnProperty("showGoogleAnalyticsBtn")) {
    var showGoogleAnalyticsBtn = config["showGoogleAnalyticsBtn"];
    var analytics_column = config["analytics_column"];
  }
  if (config.hasOwnProperty("showGoogleAdsBtn")) {
    var showGoogleAdsBtn = config["showGoogleAdsBtn"];
    var google_ads_column = config["google_ads_column"];
  }
  if (config.hasOwnProperty("showChangeBtn")) {
    var showChangeBtn = config["showChangeBtn"];
    var change_columns = config["change_columns"];
  }
  if (config.hasOwnProperty("showChangeButton")) {
    var showAbsButton = config["showAbsButton"];
    var change_columns = config["change_columns"];
    var showOrganicBtn = config["showOrganicBtn"];
    var showChangeButton = config["showChangeButton"];
    var absolute_change_columns = config["absolute_change_columns"];
  }

  if (config.hasOwnProperty("searching")) {
    var searching = config["searching"];
    var enableSelectRow = config["enableSelectRow"];
    var searchPlaceholder = config["searchPlaceholder"];
  }

  if (config.hasOwnProperty("negativeSearchTermCandidatesBtn")) {
    var negativeSearchTermCandidatesBtn =
      config["negativeSearchTermCandidatesBtn"];
  }

  if (config.hasOwnProperty("showCollapsed")) {
    var showCollapsed = config["showCollapsed"];
  }

  if (config.hasOwnProperty("apiPath")) {
    var drillDownAPiPath = config["apiPath"];
  }

  if (config.hasOwnProperty("enableColSpanColumnData")) {
    var enableColSpanColumnData = config["enableColSpanColumnData"];
  }

  if (config.hasOwnProperty("showMetrics")) {
    var showMetrics = config["showMetrics"];
  }

  if (config.hasOwnProperty("notification_link")) {
    var notification_link = config["notification_link"];
  } else {
    var notification_link = false;
  }

  if (config.hasOwnProperty("show_product_type_brand_button")) {
    var showProductBrand = config["show_product_type_brand_button"];
  }

  if (config.hasOwnProperty("tab_columns")) {
    var tabColumns = config["tab_columns"];
  }

  if (config.hasOwnProperty("tooltipText")) {
    var tooltipText = config["tooltipText"];
  }

  if (config.hasOwnProperty("showIncludeEmailSMSBtn")) {
    var showIncludeEmailSMSBtn = config["showIncludeEmailSMSBtn"];
  }

  if (config.hasOwnProperty("table_bottom_link")) {
    var table_bottom_link = config["table_bottom_link"];
  }

  if (config.hasOwnProperty("show_total_only")) {
    var show_total_only = config["show_total_only"];
  }

  if (config.hasOwnProperty("product_ads_performance_bottom_link")) {
    var product_ads_performance_bottom_link =
      config["product_ads_performance_bottom_link"];
  }

  if (config.hasOwnProperty("link_redirect")) {
    var link_redirect = config["link_redirect"];
  }

  if (config.hasOwnProperty("link_name")) {
    var link_name = config["link_name"];
  }

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      defaultExpandAllRows: true,
    },
    componentMatchReport,
  });

  const [tableParamsServer, setTableParamsServer] = useState({
    pagination: {
      current: 1,
      pageSize: limit ? limit : 50,
    },
  });
  const paginationValue =
    paginate === "false" && paginate != undefined
      ? false
      : tableParams.pagination;

  const [form] = Form.useForm();

  const onChange = (e, columnName) => {
    const isChecked = e.target.checked;

    // Create a new array based on the checked state
    let newArray = isChecked
      ? [...isHiddenColumns, columnName]
      : isHiddenColumns.filter((col) => col !== columnName);

    // Update the state variables
    setColumnShowHideModal(newArray);
    setHiddenColumns(newArray);
  };

  const { Option } = Select;

  const showFilterModal = () => {
    setIsModalOpen(true);
  };

  const handleFilterModelCancel = () => {
    setIsModalOpen(false);
  };

  const displayShowHideModal = () => {
    setIsShowHideModalOpen(true);
  };

  const handleCancelShowHideModal = () => {
    setIsShowHideModalOpen(false);
  };

  const handleShowHideModalSubmit = () => {};
  const {
    dataTable: {
      table: {
        [props?.block?.block_id]: {
          [props?.block?.block_id]: {
            data: tableData = [],
            labels: labels = [],
            nlg_title: nlg_title,
            insight: insight = [],
            nlg_text: nlg_text = [],
            goal_data: goalData = [],
            total_all: totalAll = [],
            total_data: totalData = [],
            abtest_kpi: abtest_kpi = [],
            response_data: responseData = [],
            abtest_winner: abtest_winner = "",
            clv_cohorts_val: clv_cohorts_val = [],
            api_units_usage: api_units_usage = [],
            total_all_data: totalFilteredData = [],
            subscribed_accounts: subscribedAccounts = [],
            ga_insights_data: gaInsightsTopRightData = [],
            organic_table_status: organic_table_status = [],
            columns: column_table = [],
          } = {},
          isLoading,
        } = {},
      } = {},
    } = {},
    accounts: {
      accounts: {
        data: total,
        notifications = {},
        notification_services = {},
      } = {},
    },
  } = useSelector((state) => state);
  if (responseData.length > 0) {
    var columnData = responseData;
  } else {
    var columnData = tableData;
  }

  const dispatch = useDispatch();

  const tableColumns = config["columns"] ? config["columns"] : column_table;

  var data = props?.all_input_data;
  var formData = formDataAppend(data);

  /*for filter data*/
  const { filterData } = props;

  useEffect(() => {
    let data = props?.all_input_data;
    let formData = formDataAppend(data);
    dispatch(getDataTable(formData, props));
    if (notification_link) {
      dispatch(getAllAccounts({}));
    }
  }, [filterData]);
  /*end for filter data*/

  //total data
  useEffect(() => {
    // Assuming totalFilteredData is a prop or state variable
    const totalData = { ...totalFilteredData }; // Copy the object

    const newObj = {};
    Object.keys(totalData).forEach((data) => {
      newObj[data] = totalData[data];
    });

    // Check if the new object is different from the previous totalRow
    if (!isEqual(newObj, totalRow)) {
      setTotalRow(newObj);
    }
  }, [totalFilteredData, totalRow]);

  const [loading, setLoading] = useState("");

  useEffect(() => {
    if (getFilter != undefined && Object.keys(getFilter).length != 0) {
      previousValue.current = getFilter;

      if (Object.keys(getFilter).length > 0) {
        data["filter_column"] = getFilter.filter_column;
        data["filter_condition"] = getFilter.filter_condition;
        data["filter_value"] =
          getFilter.format_value != undefined
            ? getFilter.filter_value.replace(/\$|,|%|\s/g, "")
            : getFilter.filter_value;
      } else {
        data["filter_column"] = "";
        data["filter_condition"] = "";
        data["filter_value"] = "";
      }

      if (Object.keys(getSortData).length > 0) {
        data["order_column"] = getSortData.order_column;
        data["order_by"] = getSortData.order_by;
      } else {
        data["order_column"] = defaultOrderColumn;
        data["order_by"] = defaultOrder;
      }

      var formData = formDataAppend(data);
      if (organic_table_status === true) {
        formData.append("email_sms_data", emailSmsData);
      }
      dispatch(getDataTable(formData, props));
    }
  }, [getFilter]);

  useEffect(() => {
    if (searchText != undefined && Object.keys(searchText).length != 0) {
      onSearch();
      // getData();
    }
  }, []);

  useEffect(() => {
    if (getWebUrl != undefined && getWebUrl.length > 0) {
      onSelectChange();
    }
  }, [getWebUrl]);

  useEffect(() => {
    const panelHeadingElement = document.querySelector(
      ".report-v2-panel-heading"
    );
    if (panelHeadingElement) {
      let insightsExists = panelHeadingElement.querySelector(
        ".top-right-insights"
      );
      if (!insightsExists && gaInsightsTopRightData != "") {
        const newDiv = document.createElement("div");
        newDiv.innerHTML = gaInsightsTopRightData;
        newDiv.className = "top-right-insights";
        panelHeadingElement.insertBefore(
          newDiv,
          panelHeadingElement.firstChild
        );
      }
    }
  }, [gaInsightsTopRightData]);

  useEffect(() => {
    const panelHeadingElement = document.querySelector(
      ".report-v2-panel-heading"
    );
    if (panelHeadingElement && api_units_usage.length > 0) {
      let apiUnitsExists = panelHeadingElement.querySelector(".api-units");
      if (!apiUnitsExists) {
        const newDiv = document.createElement("div");
        newDiv.className = "api-units";
        newDiv.innerHTML =
          "SEMrush API units used within specified date range:<b>" +
          " " +
          api_units_usage +
          "</b>";
        panelHeadingElement.insertBefore(
          newDiv,
          panelHeadingElement.firstChild
        );
      }
    }
  }, [api_units_usage]);

  useEffect(() => {
    if (link_name != undefined) {
      $("#row1").prepend(
        "<div class='upper-links'> <a href=" +
          link_redirect[link_name[0]] +
          props?.account_id +
          " target='_blank'>" +
          link_name[0] +
          "</a>, &nbsp;<a href=" +
          link_redirect[link_name[1]] +
          props?.account_id +
          " target='_blank'>" +
          link_name[1] +
          "</a>,&nbsp;<a href=" +
          link_redirect[link_name[2]] +
          props?.account_id +
          " target='_blank'>" +
          link_name[2] +
          "</a></div>"
      );
    }
  }, [link_name != undefined]);

  useEffect(() => {
    const panelHeadingElement = document.querySelector(
      "#row2 .report-v2-panel-body .datatable-options"
    );

    if (
      panelHeadingElement &&
      abtest_winner !== "" &&
      abtest_winner === "None"
    ) {
      const existingDiv = panelHeadingElement.querySelector(".abtest_winner");

      if (!existingDiv) {
        const newDiv = document.createElement("div");
        newDiv.className = "abtest_winner";
        newDiv.innerHTML = "- No winner ad can be established";
        panelHeadingElement.insertBefore(
          newDiv,
          panelHeadingElement.firstChild
        );
      }
    } else {
      if (panelHeadingElement) {
        // Remove the div if it exists
        const existingDiv = panelHeadingElement.querySelector(".abtest_winner");
        if (existingDiv) {
          panelHeadingElement.removeChild(existingDiv);
        }
      }
    }
  }, [abtest_winner]);

  const onFinish = (values) => {
    setIsModalOpen(false);
    setFilter(values);
    if (form.getFieldsValue()["format_value"] == undefined) {
      setFilterCondition(values["filter_condition"]);
      setFilterValue(values["filter_value"]);
    } else {
      setFilterConditionInt(values["filter_condition"]);
      setFilterValueInt(values["filter_value"]);
    }
  };

  // for toggle and export event use cases
  var apiPath = props?.block?.api_path;
  if (organic_table_status === true) {
    if (
      props &&
      props.all_input_data &&
      typeof props.all_input_data === "object"
    ) {
      Object.assign(props.all_input_data, {
        email_sms_data: emailSmsData,
      });
    }
  }

  const export_data = useCallback((data_type, props, dispatch, apiPath) => {
    exportData(data_type, props, dispatch, apiPath);
  });

  const getColumnSearchProps = (dataIndex, format) => ({
    filterDropdown: ({ confirm }) => (
      <>
        <div
          className="datatable-filter"
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Button
            onClick={() => {
              showFilterModal();
              setFormat(format);
              form.setFieldsValue({
                filter_column: dataIndex,
                filter_condition:
                  format != undefined ? filterConditionInt : filterCondition,
                filter_value:
                  format != undefined ? filterValueInt : filterValue,
                format_value: format,
              });
              confirm({
                closeDropdown: true,
              });
            }}
            icon={<FiFilter />}
            size="small"
          ></Button>
          <Button
            onClick={() => {
              displayShowHideModal();
              confirm({
                closeDropdown: true,
              });
            }}
            size="small"
            icon={<FiColumns />}
          ></Button>
        </div>
      </>
    ),
    filterIcon: (filtered) =>
      getFilter["filter_column"] == dataIndex ? (
        <FilterFilled style={{ color: "#000000" }} />
      ) : (
        <FilterOutlined style={{ color: "#000000" }} />
      ),
  });

  var columns = tableColumns;

  var columns = checkFormat(
    showGoogleAdsBtn,
    showGoogleAnalyticsBtn,
    columns,
    getColumnSearchProps,
    goalData,
    isSharePopoverVisible,
    setIsSharePopoverVisible,
    seriesData,
    setSeriesData,
    categoriesData,
    setCategoriesData,
    setAccountName,
    accountName,
    drillDownAPiPath,
    props?.all_input_data,
    setLoading,
    props,
    formData,
    dispatch
  );

  if (showGoogleAdsBtn == "true" && showGoogleAnalyticsBtn == "true") {
    var analyticsHiddenColumns = columns[5]["children"].filter(
      (item, index) => item.hidden != "true"
    );

    var adwordsHiddenColumns = columns[6]["children"].filter(
      (item, index) => item.hidden != "true"
    );

    var visibleAnalyticsAbsoluteColumns = columns[5]["children"].filter(
      (item, index) => !change_columns.includes(item.dataIndex)
    );

    var visibleAnalyticsChangeColumns = columns[5]["children"].filter(
      (item, index) => !absolute_change_columns.includes(item.dataIndex)
    );

    var visibleAdwordsAbsoluteColumns = columns[6]["children"].filter(
      (item, index) => !change_columns.includes(item.dataIndex)
    );

    var visibleAdwordsChangeColumns = columns[6]["children"].filter(
      (item, index) => !absolute_change_columns.includes(item.dataIndex)
    );

    var visibleAnalyticsAbsoluteAndChangeColumns = columns[5][
      "children"
    ].filter((item, index) => item);

    var visibleAdwordsAbsoluteAndChangeColumns = columns[6]["children"].filter(
      (item, index) => item
    );

    columns[5]["children"] = analyticsHiddenColumns;
    columns[6]["children"] = adwordsHiddenColumns;

    if (getAnalyticsColumns.length > 0) {
      columns[5]["children"] = getAnalyticsColumns;
    }
    if (getAdsColumns.length > 0) {
      columns[6]["children"] = getAdsColumns;
    }
  } else if (showChangeBtn == "true" && showAbsBtn == "true") {
    var visibleAbsoluteColumns = columns.filter(
      (item, index) => !change_columns.includes(item.dataIndex)
    );

    var visibleChangeColumns = columns.filter(
      (item, index) => !absolute_change_columns.includes(item.dataIndex)
    );

    var visibleAllColumns = columns.filter((item, index) => item);

    if (getAbsColumns != undefined && getAbsColumns.length > 0) {
      var columns = getAbsColumns.filter((col, index) => col);
    }

    if (getChangeColumns != undefined && getChangeColumns.length > 0) {
      var columns = getChangeColumns.filter((col, index) => col);
    }

    if (getAllColumn != undefined && getAllColumn.length > 0) {
      var columns = getAllColumn.filter((col, index) => col);
    }
  } else {
    var showChangeColumns = columns.filter((col, index) => col);
    var hideChangeColumns = columns.filter(
      (col, index) => col.hidden != "true"
    );

    if (getOnlyChangeCol.length > 0) {
      var columns = getOnlyChangeCol.filter((col, index) => col);
    } else {
      var columns = columns.filter((col, index) => col.hidden != "true");
    }
  }

  if (getTitle.length > 0) {
    var columns = columns.filter((col) => !getTitle.includes(col["title"]));
  }

  if (
    getAbsColumns != undefined &&
    getAbsColumns.length == 0 &&
    getChangeColumns != undefined &&
    getChangeColumns.length == 0
  ) {
    var columns = columns.filter((col, index) => col.hidden != "true");
  }

  var columns = columns.filter((col, index) => col);

  if (isHiddenColumns.length > 0) {
    columns = columns.filter((col) => !isHiddenColumns.includes(col.dataIndex));
  }

  const changeAnalyticsColumns = (status, columns) => {
    if (status == false) {
      var newarray = getTitle.concat("Google Analytics");
    } else {
      var newarray = arr_diff(getTitle, ["Google Analytics"]);
    }

    setTitle(newarray);
  };

  const changeAdsColumns = (status) => {
    if (status == false) {
      var newarray = getTitle.concat("Google Ads");
    } else {
      var newarray = arr_diff(getTitle, ["Google Ads"]);
    }

    setTitle(newarray);
  };

  const changeAbsAndChangeColumns = (status, key) => {
    setKey({ ...getKey, [key]: status });
    var value = { ...getKey, [key]: status };

    if (value["absoluteColumns"] == true && value["changeColumns"] == true) {
      setAllColumn(visibleAllColumns);
      setAdsColumns(visibleAdwordsAbsoluteAndChangeColumns);
      setAnalyticsColumns(visibleAnalyticsAbsoluteAndChangeColumns);
    } else if (
      value["absoluteColumns"] == true &&
      value["changeColumns"] == false
    ) {
      setAllColumn([]);
      setChangeColumns([]);
      setAbsColumns(visibleAbsoluteColumns);
      setAdsColumns(visibleAdwordsAbsoluteColumns);
      setAnalyticsColumns(visibleAnalyticsAbsoluteColumns);
    } else if (
      value["absoluteColumns"] == false &&
      value["changeColumns"] == true
    ) {
      setAllColumn([]);
      setAbsColumns([]);
      setOnlyChangeCol(showChangeColumns);
      setChangeColumns(visibleChangeColumns);
      setAdsColumns(visibleAdwordsChangeColumns);
      setAnalyticsColumns(visibleAnalyticsChangeColumns);
    } else {
      setAllColumn([]);
      setAbsColumns([]);
      setChangeColumns([]);
      setAdsColumns(adwordsHiddenColumns);
      setOnlyChangeCol(hideChangeColumns);
      setAnalyticsColumns(analyticsHiddenColumns);
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };
  const onSelectRowKeyChange = (newSelectedRowKeys, selectedRows) => {
    if (newSelectedRowKeys.length == 2) {
      let campaign_goal = selectedRows;
      let selected_campaign_goal = campaign_goal[0]["campaign_goal"];
      if (
        campaign_goal[0]["campaign_goal"] !== campaign_goal[1]["campaign_goal"]
      ) {
        const type = "info";
        const message = "";
        const description = "Ads with different goal type cannot be selected.";
        openNotificationWithIcon(type, message, description);
        setRowKeySelected(newSelectedRowKeys.slice(0, 2));
      } else {
        setRowKeySelected(newSelectedRowKeys);
        paid_social_ab_testing(newSelectedRowKeys, selected_campaign_goal);
        setAbTest(true);
      }
    } else if (newSelectedRowKeys.length === 0) {
      setRowKeySelected(newSelectedRowKeys.slice(0, 2));
      paid_social_ab_testing(newSelectedRowKeys, "");
    } else {
      setRowKeySelected(newSelectedRowKeys.slice(0, 2));
    }
  };

  const rowKeySelection = {
    selectedRowKeys: rowKeySelected,
    onChange: (newSelectedRowKeys, selectedRows) => {
      onSelectRowKeyChange(newSelectedRowKeys, selectedRows);
    },
  };

  const onSearch = (value, event) => {
    if (event && event.key === "Enter") {
      event.preventDefault();
      return;
    }
    // setSearchText(value);
    setSearchText((prevSearchText) => {
      return value;
    });
    data["search[value]"] = value;
    data["search[regex]"] = false;
    var formData = formDataAppend(data);
    dispatch(getDataTable(formData, props));
  };

  const handleChangeServerSide = (pagination, filters, sorter) => {
    setTableParamsServer({
      pagination,
    });
    const limit = pagination.pageSize;

    const currentPageIndex = pagination.current - 1;
    const offsets = currentPageIndex * limit;

    const data = {
      ...props?.all_input_data,
      limit,
      offset: offsets,
    };

    if (sorter.hasOwnProperty("column")) {
      const sortOrder = sorter.order === "ascend" ? "asc" : "desc";
      var sortData = { order_column: sorter.field, order_by: sortOrder };
      setSortData({ order_column: sorter.field, order_by: sortOrder });
      data["order_column"] = sortData.order_column;
      data["order_by"] = sortData.order_by;
    }

    if (
      previousValue.current != undefined &&
      Object.keys(previousValue.current).length != 0
    ) {
      data["filter_column"] = previousValue.current.filter_column;
      data["filter_condition"] = previousValue.current.filter_condition;
      data["filter_value"] = previousValue.current.filter_value;
    } else {
      data["filter_column"] = "";
      data["filter_condition"] = "";
      data["filter_value"] = "";
    }

    var formData = formDataAppend(data);
    dispatch(getDataTable(formData, props));
  };

  const submitKeyword = () => {
    var confirmContent = "";
    selectedRowKeys.map((val) => {
      confirmContent += "-" + val;
    });
    var title =
      "Are you sure you wish the following" +
      " " +
      selectedRowKeys.length +
      " " +
      "pages for keyword research?";
    Modal.confirm({
      title: title,
      content: confirmContent,
      onOk: submit,
    });
  };

  const submit = async () => {
    var account_id = props?.account_id;
    var client_id = props?.client_id;
    try {
      const bodyData = {
        page_urls: selectedRowKeys,
        account_id: account_id,
        client_id: client_id,
      };
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: {
          authorization: localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      };
      setLoading(true);
      const response = await fetch(
        baseUrl_API + "api/v1/save-lpp-url",
        requestOptions
      );
      const result = await response.json();
    } catch (err) {
      // Handle any errors
    } finally {
      setLoading(false);
      alert("Success! Selected pages were submitted for keyword research.");
      dispatch(getDataTable(formData, props));
      setSelectedRowKeys([]);
    }
  };

  const datatable_export_menu = (
    <Menu
      className="export-dropdown"
      items={[
        {
          label: (
            <Button
              onClick={() => export_data("excel", props, dispatch, apiPath)}
            >
              <FaFileExcel />
              Excel
            </Button>
          ),
          key: "0",
        },
        {
          label: (
            <Button
              onClick={() => export_data("csv", props, dispatch, apiPath)}
            >
              <FaFileCsv />
              CSV
            </Button>
          ),
          key: "1",
        },
      ]}
    />
  );

  useEffect(() => {
    if (columnData?.length && defaultExpand && !hasClicked) {
      expandCollpaseData();
      setExpandedAll(true);
    }
  }, [columnData?.length]);

  const clearFilterForm = () => {
    setIsModalOpen(false);

    data["filter_column"] = "";
    data["filter_condition"] = "";
    data["filter_value"] = "";
    setFilterCondition("equal");
    setFilterValue("");
    setFilterConditionInt("equal");
    setFilterValueInt("");
    setFilter("");
    previousValue.current = "";

    var formData = formDataAppend(data);

    dispatch(getDataTable(formData, props));
  };

  //start column manipulations as it supports colspan column as well as normal column
  const handleGlobalExpandCollapse = () => {
    setHasClicked(true);
    if (expandedAll) {
      setExpandedRowKeys([]);
    } else {
      expandCollpaseData();
    }
    setExpandedAll(!expandedAll);
    setHasClicked(false);
  };

  const expandCollpaseData = () => {
    // Find all keys of rows with children or nested children
    const keysWithChildren = [];
    const findKeysRecursively = (records) => {
      for (const record of records) {
        keysWithChildren.push(record.key);
        if (record.children) {
          findKeysRecursively(record.children);
        }
      }
    };
    findKeysRecursively(columnData);
    setExpandedRowKeys(keysWithChildren);
  };

  const getColumnVisibilityInitialState = (columnKeys, config) => {
    const initialVisibility = {};
    if (config !== undefined && columnKeys !== undefined) {
      columnKeys.forEach((columnKey) => {
        if (config["organic_metrics_columns"] !== undefined) {
          initialVisibility[columnKey] =
            config["organic_metrics_columns"].includes(columnKey);
        } else {
          initialVisibility[columnKey] = false;
        }
      });
    }
    return initialVisibility;
  };

  const useColumnVisibilityState = (columnKeys, config) => {
    const [visibility, setVisibility] = useState(() =>
      getColumnVisibilityInitialState(columnKeys, config)
    );

    const toggleColumnVisibility = (e) => {
      setVisibility((prevState) => {
        const updatedVisibility = { ...prevState };
        if (config !== undefined && columnKeys !== undefined) {
          columnKeys.forEach((columnKey) => {
            updatedVisibility[columnKey] = e;
          });
        }
        return updatedVisibility;
      });
    };

    return [visibility, toggleColumnVisibility];
  };

  const [changeColumnVisibility, toggleChangeColumnVisibility] =
    useColumnVisibilityState(config["change_columns"], config);

  const [absoluteChangeColumnVisibility, toggleAbsoluteChangeColumnVisibility] =
    useColumnVisibilityState(config["absolute_change_columns"], config);

  const [metricsChangeColumnVisibility, toggleMetricsChangeColumnVisibility] =
    useColumnVisibilityState(config["organic_metrics_columns"], config);

  const [modalChangeColumnVisibility, toggleModalChangeColumnVisibility] =
    useColumnVisibilityState(columnShowHideModal, columnShowHideModal);

  const getColumnsTabsToHide = (tab) => {
    const tabToColumnsMapping = config["tabs_to_column_mappings"] || {};
    return tabToColumnsMapping[tab] || [];
  };

  const renderColumns = (
    columns,
    getColumnSearchProps,
    setLoading,
    props,
    clv_cohorts_val,
    goalData,
    notifications,
    notification_services,
    abtest_winner,
    abtest_kpi
  ) => {
    const pathArray = window.location?.pathname?.split("/");
    let report_key_name = pathArray[4];

    //for tab show hide
    const columnsTabsToHide = getColumnsTabsToHide(activeTab);

    return columns
      .map((column, indexCol) => {
        const columnHeaderTooltip = column.tooltip ? (
          <Tooltip title={column.tooltip}>
            <span style={{ color: "#8dc441", marginLeft: "4px" }}>
              <FaInfoCircle />
            </span>
          </Tooltip>
        ) : column.modal_data ? (
          <>
            <span style={{ color: "#8dc441" }} onClick={handleInfoClick}>
              <FaInfoCircle />
            </span>

            <Modal
              title="Insights Description"
              visible={isModalVisible}
              // onOk={closeModal}
              onCancel={closeModal}
            >
              {
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: column?.modal_data,
                    }}
                  />
                </>
              }
            </Modal>
          </>
        ) : null;
        if (
          column.children &&
          props?.all_input_data?.include_amazon_data === 0 &&
          props?.block?.report_key === "media_mix_modeling_v2"
        ) {
          return {
            ...column,
            children: column.children
              .map((child) => {
                if (
                  (props?.all_input_data?.include_amazon_data === 0 &&
                    child.dataIndex === "asc_spend_perc") ||
                  child.dataIndex === "asc_lift"
                ) {
                  return null;
                }
                return {
                  ...child,
                  render: (text, record) => {
                    return (
                      <>
                        {
                          <FormattedNumberCell
                            value={text}
                            format={child.format}
                            metric={child.dataIndex}
                          />
                        }
                      </>
                    );
                  },
                };
              })
              .filter(Boolean),
          };
        } else if (column.children) {
          return {
            ...column,
            children: renderColumns(
              column.children,
              getColumnSearchProps,
              setLoading,
              props,
              clv_cohorts_val,
              goalData,
              notifications,
              notification_services,
              abtest_winner,
              abtest_kpi
            ),
            title: <>{column.title}</>,
            render: (text, record) => (
              <span>
                {renderColumns(
                  column.children,
                  getColumnSearchProps,
                  setLoading,
                  props,
                  clv_cohorts_val,
                  goalData,
                  notifications,
                  notification_services,
                  abtest_winner,
                  abtest_kpi
                ).map((nestedColumn) => (
                  <span key={nestedColumn.dataIndex}>
                    {
                      <Tooltip
                        trigger="hover"
                        title={record[nestedColumn.tooltip]}
                      >
                        <span style={{ color: "#8dc441" }}>
                          <FaInfoCircle />
                        </span>
                      </Tooltip>
                    }
                    {nestedColumn.render
                      ? nestedColumn.render(
                          record[nestedColumn.dataIndex],
                          record
                        )
                      : record[nestedColumn.dataIndex]}
                    {" | "}
                  </span>
                ))}
              </span>
            ),
          };
        } else {
          const matchReport = componentMatchReport[report_key_name];
          const columnProps = {
            ...column,
            title: (
              <>
                {column.global_expand_collpase == "true" && (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={handleGlobalExpandCollapse}
                  >
                    {expandedAll ? <MinusOutlined /> : <PlusOutlined />}
                    {/* {expandedAll ? <PlusOutlined /> : <MinusOutlined />} */}
                  </span>
                )}
                {column.title}
                {columnHeaderTooltip}
              </>
            ),
            sorter: column.sorter == "false" ? false : true,
            sortDirections: ["descend", "ascend"],
            fixed: column.fixed != undefined ? column.fixed : "",
            ...(column.filter === "false"
              ? false
              : getColumnSearchProps(column.dataIndex, column.format)),
            // ...getColumnSearchProps(column.dataIndex, column.format),
            render: (text, record, nestedColumnIndex) => (
              <>
                {
                  <FormattedNumberCell
                    value={text}
                    format={column.format}
                    metric={column.dataIndex}
                  />
                }
              </>
            ),
          };

          if (matchReport) {
            return {
              ...columnProps,
              render: (text, record) => {
                const ValidComponent = tableCellHelper(
                  report_key_name,
                  text,
                  record,
                  column,
                  setLoading,
                  props,
                  clv_cohorts_val,
                  goalData,
                  notifications,
                  notification_services,
                  abtest_winner,
                  abtest_kpi
                );
                return (
                  <>
                    {record.hasOwnProperty("children") &&
                    Array.isArray(record.children) ? (
                      <>
                        <strong>{ValidComponent}</strong>
                      </>
                    ) : (
                      <>
                        {/* special case added for amd */}
                        {record?.service_new === "" ? (
                          <strong>{ValidComponent}</strong>
                        ) : (
                          ValidComponent
                        )}
                      </>
                    )}
                  </>
                );
              },
            };
          } else {
            return {
              ...columnProps,
              goalData,
              render: (text, record, nestedColumn) => (
                <>
                  {
                    <FormattedNumberCell
                      value={text}
                      format={column.format}
                      metric={column.dataIndex}
                    />
                  }
                </>
              ),
            };
          }
        }
      })
      .filter((column) => {
        const isVisibleByDefault = CustomColumnFilter({
          column,
          changeColumnVisibility,
          absoluteChangeColumnVisibility,
          metricsChangeColumnVisibility,
          modalChangeColumnVisibility,
          config,
          columnsTabsToHide,
          columnShowHideModal,
        });
        return isVisibleByDefault;
      });
  };

  //end of column manipulations

  const getRenderColumnData = renderColumns(
    tableColumns,
    getColumnSearchProps,
    setLoading,
    props,
    clv_cohorts_val,
    goalData,
    notifications,
    notification_services,
    abtest_winner,
    abtest_kpi
  );

  const updateDataAndFetchTable = useCallback(
    (dataUpdateFn, key) => {
      const updateProps = JSON.parse(JSON.stringify(props));
      const data = { ...props?.all_input_data };
      dataUpdateFn(data, key, updateProps);
      const formData = formDataAppend(data);
      dispatch(getDataTable(formData, updateProps));
    },
    [dispatch, props]
  );

  const handleMetricChange = useCallback(
    (key) => {
      updateDataAndFetchTable((data, key) => {
        data.is_troas_mer = key ? 1 : 0;
      }, key);
    },
    [updateDataAndFetchTable]
  );

  const handleTabProductType = useCallback(
    (key) => {
      setActiveTab(key);
      updateDataAndFetchTable((data, key) => {
        data.view_mode = key;
      }, key);
    },
    [updateDataAndFetchTable]
  );

  const includeEmailSmsData = useCallback(
    (key) => {
      setEmailSmsData(key ? 1 : 0);
      dispatch(setActiveTable(key));
      updateDataAndFetchTable((data, key) => {
        data.email_sms_data = key ? 1 : 0;
      }, key);
    },
    [updateDataAndFetchTable]
  );

  const paid_social_ab_testing = useCallback(
    (key, selected_campaign_goal) => {
      updateDataAndFetchTable((data, key, props) => {
        setAbTest(true);
        let abtest_ad1 = key[0];
        let abtest_ad2 = key[1];
        data.abtest_ad1 = abtest_ad1;
        data.abtest_ad2 = abtest_ad2;
        data.abtest_campaign_goal = selected_campaign_goal;
        props.block = {
          ...props?.block,
          block_id: 318,
          api_path: "v2/psm_ad_performance/ab_test",
        };
      }, key);
    },
    [updateDataAndFetchTable]
  );

  if (goalData.hasOwnProperty("pla_ppc_roas_target_goal")) {
    var pla_ppc_roas_target_goal = goalData.pla_ppc_roas_target_goal;
  }

  if (goalData.hasOwnProperty("roas_goal")) {
    var roas_goal = goalData.roas_goal;
  }

  if (goalData.hasOwnProperty("acos_goal")) {
    var acos_goal = goalData.acos_goal;
  }

  const showModal = () => {
    setIsSharePopoverVisible(true);
  };

  const handleCancel = () => {
    setIsSharePopoverVisible(false);
  };

  if (organic_table_status === false) {
    $("#row1, #annotationrow1").css("display", "none");
  }

  // TODO need to make custom component todo(bing_feed_label_performance)
  $(".label_total_all").text("Total-No value");
  $(".label_total_filter").text("Total");

  // if (abTest === false) {
  //   $("#row2col1").css("display", "none");
  // } else {
  //   $("#row2col1").css("display", "block");
  //   var row2col1Element = document.getElementById("row2col1");
  //   if (row2col1Element) {
  //     row2col1Element.style.display = "flex";
  //   } else {
  //   }
  // }

  // if (columnData.length === 0) {
  //   $("#row2col1").css("display", "none");
  // } else {
  //   $("#row2col1").css("display", "block");
  //   var row2col1Element = document.getElementById("row2col1");
  //   if (row2col1Element) {
  //     row2col1Element.style.display = "flex";
  //   } else {
  //   }
  // }

  // $("#annotationrow2").css("display", "none");

  const addToCampaigns = async () => {
    var account_id = props?.account_id;
    try {
      const bodyData = {
        negative_keywords: selectedRowKeys,
        account_id: account_id,
      };
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: {
          authorization: localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      };
      setLoading(true);
      const response = await fetch(
        baseUrl_API + "api/v1/negative-keywords/add-to-campaign",
        requestOptions
      );
      const result = await response.json();
      alert(result.message);
    } catch (err) {
      console.log(err);
      // Handle any errors
    } finally {
      setLoading(false);
      dispatch(getDataTable(formData, props));
      setSelectedRowKeys([]);
    }
  };

  const addToAdGroups = async () => {
    var account_id = props?.account_id;

    try {
      const bodyData = {
        negative_keywords: selectedRowKeys,
        account_id: account_id,
      };
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: {
          authorization: localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      };
      setLoading(true);
      const response = await fetch(
        baseUrl_API + "api/v1/negative-keywords/add-to-adgroup",
        requestOptions
      );
      const result = await response.json();
      setLoading(false);
      alert(result.message);
      dispatch(getDataTable(formData, props));
      setSelectedRowKeys([]);
    } catch (err) {
      console.log(err);
      // Handle any errors
    }
  };

  return (
    <>
      <div className="datatable-options">
        <CustomBtnSwitchTabComponents
          columns={columns}
          onSearch={onSearch}
          searching={searching}
          roas_goal={roas_goal}
          acos_goal={acos_goal}
          showAbsBtn={showAbsBtn}
          tabColumns={tabColumns}
          showMetrics={showMetrics}
          tooltipText={tooltipText}
          FaInfoCircle={FaInfoCircle}
          showChangeBtn={showChangeBtn}
          showAbsButton={showAbsButton}
          showOrganicBtn={showOrganicBtn}
          showGoogleAdsBtn={showGoogleAdsBtn}
          showChangeButton={showChangeButton}
          changeAdsColumns={changeAdsColumns}
          showProductBrand={showProductBrand}
          searchPlaceholder={searchPlaceholder}
          handleMetricChange={handleMetricChange}
          includeEmailSmsData={includeEmailSmsData}
          handleTabProductType={handleTabProductType}
          showGoogleAnalyticsBtn={showGoogleAnalyticsBtn}
          changeAnalyticsColumns={changeAnalyticsColumns}
          showIncludeEmailSMSBtn={showIncludeEmailSMSBtn}
          pla_ppc_roas_target_goal={pla_ppc_roas_target_goal}
          changeAbsAndChangeColumns={changeAbsAndChangeColumns}
          toggleChangeColumnVisibility={toggleChangeColumnVisibility}
          toggleMetricsChangeColumnVisibility={
            toggleMetricsChangeColumnVisibility
          }
          toggleAbsoluteChangeColumnVisibility={
            toggleAbsoluteChangeColumnVisibility
          }
          includeEmailSMS={activeToggleData}
        ></CustomBtnSwitchTabComponents>
        <div className="export-button-wrapper">
          <Dropdown overlay={datatable_export_menu} trigger={["click"]}>
            <Button type="primary" onClick={(e) => e.preventDefault()}>
              <FaDownload />
              Export
            </Button>
          </Dropdown>
        </div>
      </div>
      <div></div>

      <div className="datatable-content">
        {enableSelectRow == "true" ? (
          <Table
            className={name}
            id="basic-table"
            rowKey={(record) =>
              record.web_url != undefined ? record.web_url : record
            }
            rowSelection={rowSelection}
            columns={columns}
            dataSource={columnData}
            //loading={isLoading}
            onChange={handleChangeServerSide}
            scroll={{ x: "max-content" }}
            locale={{
              emptyText: isLoading ? (
                <SpinComponent />
              ) : columnData?.length === 0 && isLoading == true ? (
                <SpinComponent />
              ) : columnData?.length === 0 && isLoading == false ? (
                <span style={{ fontSize: "18px", marginLeft: "50%" }}>
                  No Data
                </span>
              ) : (
                <SpinComponent />
              ),
            }}
            pagination={
              totalData === null
                ? paginationValue
                : {
                    ...tableParamsServer.pagination,
                    total: totalData,
                    showSizeChanger: false,
                  }
            }
            bordered
            filter={getColumnSearchProps}
            summary={() => {
              return (
                <>
                  {totalFilteredData &&
                  Object.keys(totalFilteredData).length > 1 ? (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell
                          key={Math.random()}
                        ></Table.Summary.Cell>
                        {columns.map((column, columnIndex) => {
                          const isFirstColumn = columnIndex === 0;
                          if (column?.children) {
                            // Handle grouped columns
                            return (
                              <React.Fragment key={columnIndex}>
                                {isFirstColumn ? (
                                  <Table.Summary.Cell
                                    index={columnIndex}
                                    className="fixed-footer"
                                  >
                                    TOTAL ALL
                                  </Table.Summary.Cell>
                                ) : (
                                  column.children.map(
                                    (childColumn, childIndex) => (
                                      <Table.Summary.Cell key={childIndex}>
                                        <FormattedNumberCell
                                          value={
                                            totalAll[childColumn.dataIndex]
                                          }
                                          format={childColumn.format}
                                          metric={childColumn.dataIndex}
                                        />
                                      </Table.Summary.Cell>
                                    )
                                  )
                                )}
                              </React.Fragment>
                            );
                          } else {
                            // Handle individual columns
                            return (
                              <>
                                <Table.Summary.Cell key={columnIndex}>
                                  {isFirstColumn ? (
                                    "TOTAL ALL"
                                  ) : (
                                    <FormattedNumberCell
                                      value={totalAll[column.dataIndex]}
                                      format={column.format}
                                      metric={column.dataIndex}
                                    />
                                  )}
                                </Table.Summary.Cell>
                              </>
                            );
                          }
                        })}
                      </Table.Summary.Row>
                      <Table.Summary.Row>
                        <>
                          <Table.Summary.Cell
                            key={Math.random()}
                          ></Table.Summary.Cell>
                        </>
                        {columns.map((column, columnIndex) => {
                          const isFirstColumn = columnIndex === 0;
                          if (column?.children) {
                            // Handle grouped columns
                            return (
                              <React.Fragment key={columnIndex}>
                                {isFirstColumn ? (
                                  <Table.Summary.Cell index={columnIndex}>
                                    TOTAL FILTERED
                                  </Table.Summary.Cell>
                                ) : (
                                  column.children.map(
                                    (childColumn, childIndex) => (
                                      <Table.Summary.Cell key={childIndex}>
                                        <FormattedNumberCell
                                          value={
                                            totalRow[childColumn.dataIndex]
                                          }
                                          format={childColumn.format}
                                          metric={childColumn.dataIndex}
                                        />
                                      </Table.Summary.Cell>
                                    )
                                  )
                                )}
                              </React.Fragment>
                            );
                          } else {
                            return (
                              <>
                                <Table.Summary.Cell key={columnIndex}>
                                  {isFirstColumn ? (
                                    "TOTAL FILTERED"
                                  ) : (
                                    <FormattedNumberCell
                                      value={totalRow[column.dataIndex]}
                                      format={column.format}
                                      metric={column.dataIndex}
                                    />
                                  )}
                                </Table.Summary.Cell>
                              </>
                            );
                          }
                        })}
                      </Table.Summary.Row>
                    </>
                  ) : null}
                </>
              );
            }}

            // summary={getAllTotal}
          />
        ) : enableColSpanColumnData == "true" ? (
          <Table
            showSorterTooltip={{
              title: "Click to sort",
              placement: "topRight",
              arrowPointAtCenter: false,
              autoAdjustOverflow: true,
            }}
            key={(record) => record.primaryKey}
            id={name}
            rowKey={
              checkBoxRowSelection === "true"
                ? (record) => record.ad_id
                : undefined
            }
            rowSelection={
              checkBoxRowSelection === "true" ? rowKeySelection : undefined
            }
            columns={getRenderColumnData}
            dataSource={columnData}
            loading={columnData?.length === 0 ? false : isLoading}
            locale={{
              emptyText:
                columnData?.length === 0 && isLoading == true ? (
                  <SpinComponent />
                ) : columnData?.length === 0 && isLoading == false ? (
                  <span style={{ fontSize: "18px", marginLeft: "50%" }}>
                    No Data
                  </span>
                ) : (
                  <SpinComponent />
                ),
            }}
            onChange={handleChangeServerSide}
            defaultExpandAllRows={false}
            expandedRowKeys={expandedRowKeys}
            onExpand={(expanded, record) => {
              if (expanded) {
                setExpandedRowKeys([...expandedRowKeys, record.key]);
              } else {
                setExpandedRowKeys(
                  expandedRowKeys.filter((key) => key !== record.key)
                );
              }
            }}
            scroll={{ x: "max-content" }}
            pagination={
              totalData === null
                ? paginationValue
                : {
                    ...tableParamsServer.pagination,
                    total: totalData,
                    showSizeChanger: false,
                  }
            }
            bordered
            summary={() => {
              return (
                <>
                  {totalFilteredData &&
                  Object.keys(totalFilteredData).length > 1 ? (
                    <>
                      {show_total_only == "true" ? (
                        <Table.Summary.Row>
                          <>
                            {/* <Table.Summary.Cell
                              key={Math.random()}
                            ></Table.Summary.Cell> */}
                          </>
                          {getRenderColumnData.map((column, columnIndex) => {
                            const isFirstColumn = columnIndex === 0;
                            if (column?.children) {
                              // Handle grouped columns
                              return (
                                <React.Fragment key={columnIndex}>
                                  {isFirstColumn ? (
                                    <Table.Summary.Cell index={columnIndex}>
                                      TOTAL
                                    </Table.Summary.Cell>
                                  ) : (
                                    column.children.map(
                                      (childColumn, childIndex) => (
                                        <Table.Summary.Cell key={childIndex}>
                                          <FormattedNumberCell
                                            value={
                                              totalRow[childColumn.dataIndex]
                                            }
                                            format={childColumn.format}
                                            metric={childColumn.dataIndex}
                                          />
                                        </Table.Summary.Cell>
                                      )
                                    )
                                  )}
                                </React.Fragment>
                              );
                            } else {
                              return (
                                <>
                                  <Table.Summary.Cell
                                    key={columnIndex}
                                    className={`${
                                      isFirstColumn ? "fixed-footer" : ""
                                    }`}
                                  >
                                    {isFirstColumn ? (
                                      "TOTAL"
                                    ) : (
                                      <FormattedNumberCell
                                        value={totalRow[column.dataIndex]}
                                        format={column.format}
                                        metric={column.dataIndex}
                                      />
                                    )}
                                  </Table.Summary.Cell>
                                </>
                              );
                            }
                          })}
                        </Table.Summary.Row>
                      ) : (
                        <>
                          <Table.Summary.Row>
                            {/* <Table.Summary.Cell
                              key={Math.random()}
                            ></Table.Summary.Cell> */}
                            {checkBoxRowSelection === "true" ? (
                              <Table.Summary.Cell
                                key={Math.random()}
                              ></Table.Summary.Cell>
                            ) : (
                              ""
                            )}
                            {getRenderColumnData.map((column, columnIndex) => {
                              const isFirstColumn = columnIndex === 0;
                              if (column?.children) {
                                // Handle grouped columns
                                return (
                                  <React.Fragment key={columnIndex}>
                                    {isFirstColumn ? (
                                      <Table.Summary.Cell
                                        index={columnIndex}
                                        className="fixed-footer"
                                      >
                                        TOTAL ALL
                                      </Table.Summary.Cell>
                                    ) : (
                                      column.children.map(
                                        (childColumn, childIndex) => (
                                          <Table.Summary.Cell key={childIndex}>
                                            <FormattedNumberCell
                                              value={
                                                totalAll[childColumn.dataIndex]
                                              }
                                              format={childColumn.format}
                                              metric={childColumn.dataIndex}
                                            />
                                          </Table.Summary.Cell>
                                        )
                                      )
                                    )}
                                  </React.Fragment>
                                );
                              } else {
                                // Handle individual columns
                                return (
                                  <>
                                    <Table.Summary.Cell
                                      key={columnIndex}
                                      className={`${
                                        column.dataIndex
                                      }_total_all ${
                                        isFirstColumn ? "fixed-footer" : ""
                                      }`}
                                    >
                                      {isFirstColumn ? (
                                        "TOTAL ALL"
                                      ) : (
                                        <FormattedNumberCell
                                          value={totalAll[column.dataIndex]}
                                          format={column.format}
                                          metric={column.dataIndex}
                                        />
                                      )}
                                    </Table.Summary.Cell>
                                  </>
                                );
                              }
                            })}
                          </Table.Summary.Row>
                          <Table.Summary.Row>
                            <>
                              {/* <Table.Summary.Cell
                                key={Math.random()}
                              ></Table.Summary.Cell> */}
                              {checkBoxRowSelection === "true" ? (
                                <Table.Summary.Cell
                                  key={Math.random()}
                                ></Table.Summary.Cell>
                              ) : (
                                ""
                              )}
                            </>
                            {getRenderColumnData.map((column, columnIndex) => {
                              const isFirstColumn = columnIndex === 0;
                              if (column?.children) {
                                // Handle grouped columns
                                return (
                                  <React.Fragment key={columnIndex}>
                                    {isFirstColumn ? (
                                      <Table.Summary.Cell
                                        index={columnIndex}
                                        className={
                                          column.dataIndex +
                                          columnIndex +
                                          "total_filter" +
                                          (isFirstColumn ? " fixed-footer" : "")
                                        }
                                      >
                                        TOTAL FILTERED
                                      </Table.Summary.Cell>
                                    ) : (
                                      column.children.map(
                                        (childColumn, childIndex) => (
                                          <Table.Summary.Cell key={childIndex}>
                                            <FormattedNumberCell
                                              value={
                                                totalRow[childColumn.dataIndex]
                                              }
                                              format={childColumn.format}
                                              metric={childColumn.dataIndex}
                                            />
                                          </Table.Summary.Cell>
                                        )
                                      )
                                    )}
                                  </React.Fragment>
                                );
                              } else {
                                return (
                                  <>
                                    <Table.Summary.Cell
                                      key={columnIndex}
                                      className={
                                        column.dataIndex +
                                        columnIndex +
                                        "total_filter" +
                                        (isFirstColumn ? " fixed-footer" : "")
                                      }
                                    >
                                      {isFirstColumn ? (
                                        "TOTAL FILTERED"
                                      ) : (
                                        <FormattedNumberCell
                                          value={totalRow[column.dataIndex]}
                                          format={column.format}
                                          metric={column.dataIndex}
                                        />
                                      )}
                                    </Table.Summary.Cell>
                                  </>
                                );
                              }
                            })}
                          </Table.Summary.Row>
                        </>
                      )}
                    </>
                  ) : null}
                </>
              );
            }}
          />
        ) : (
          <Table
            key={(record) => record.primaryKey}
            id={name}
            columns={columns}
            dataSource={columnData}
            loading={columnData?.length === 0 ? false : isLoading}
            locale={{
              emptyText:
                columnData?.length === 0 && isLoading == true ? (
                  <SpinComponent />
                ) : columnData?.length === 0 && isLoading == false ? (
                  <span style={{ fontSize: "18px", marginLeft: "50%" }}>
                    No Data
                  </span>
                ) : (
                  <SpinComponent />
                ),
            }}
            onChange={handleChangeServerSide}
            scroll={{ x: "max-content" }}
            multiple={true}
            expandedRowKeys={expandedRowKeys}
            onExpand={(expanded, record) => {
              if (expanded) {
                setExpandedRowKeys([...expandedRowKeys, record.key]);
              } else {
                setExpandedRowKeys(
                  expandedRowKeys.filter((key) => key !== record.key)
                );
              }
            }}
            pagination={
              totalData === null
                ? paginationValue
                : {
                    ...tableParamsServer.pagination,
                    total: totalData,
                    showSizeChanger: false,
                  }
            }
            bordered
            filter={getColumnSearchProps}
            // defaultExpandAllRows={true}
            summary={() => {
              return (
                <>
                  {totalFilteredData &&
                  Object.keys(totalFilteredData).length > 1 ? (
                    <>
                      {show_total_only == "true" ? (
                        <>
                          {" "}
                          <Table.Summary.Row>
                            <>
                              {/* <Table.Summary.Cell
                            key={Math.random()}
                          ></Table.Summary.Cell> */}
                            </>
                            {columns.map((column, columnIndex) => {
                              const isFirstColumn = columnIndex === 0;
                              if (column?.children) {
                                // Handle grouped columns
                                return (
                                  <React.Fragment key={columnIndex}>
                                    {isFirstColumn ? (
                                      <Table.Summary.Cell index={columnIndex}>
                                        TOTAL
                                      </Table.Summary.Cell>
                                    ) : (
                                      column.children.map(
                                        (childColumn, childIndex) => (
                                          <Table.Summary.Cell key={childIndex}>
                                            <FormattedNumberCell
                                              value={
                                                totalRow[childColumn.dataIndex]
                                              }
                                              format={childColumn.format}
                                              metric={childColumn.dataIndex}
                                            />
                                          </Table.Summary.Cell>
                                        )
                                      )
                                    )}
                                  </React.Fragment>
                                );
                              } else {
                                return (
                                  <>
                                    <Table.Summary.Cell key={columnIndex}>
                                      {isFirstColumn ? (
                                        "TOTAL"
                                      ) : (
                                        <FormattedNumberCell
                                          value={totalRow[column.dataIndex]}
                                          format={column.format}
                                          metric={column.dataIndex}
                                        />
                                      )}
                                    </Table.Summary.Cell>
                                  </>
                                );
                              }
                            })}
                          </Table.Summary.Row>
                        </>
                      ) : (
                        <>
                          {" "}
                          <Table.Summary.Row>
                            {/* <Table.Summary.Cell
                          key={Math.random()}
                        ></Table.Summary.Cell> */}
                            {columns.map((column, columnIndex) => {
                              const isFirstColumn = columnIndex === 0;
                              if (column?.children) {
                                // Handle grouped columns
                                return (
                                  <React.Fragment key={columnIndex}>
                                    {isFirstColumn ? (
                                      <Table.Summary.Cell
                                        index={columnIndex}
                                        className={`${
                                          column.dataIndex
                                        }_total_all ${
                                          isFirstColumn ? "fixed-footer" : ""
                                        }`}
                                      >
                                        TOTAL ALL
                                      </Table.Summary.Cell>
                                    ) : (
                                      column.children.map(
                                        (childColumn, childIndex) => (
                                          <Table.Summary.Cell key={childIndex}>
                                            <FormattedNumberCell
                                              value={
                                                totalRow[childColumn.dataIndex]
                                              }
                                              format={childColumn.format}
                                              metric={childColumn.dataIndex}
                                            />
                                          </Table.Summary.Cell>
                                        )
                                      )
                                    )}
                                  </React.Fragment>
                                );
                              } else {
                                // Handle individual columns
                                return (
                                  <>
                                    <Table.Summary.Cell
                                      key={columnIndex}
                                      className={`${
                                        column.dataIndex
                                      }_total_all ${
                                        isFirstColumn ? "fixed-footer" : ""
                                      }`}
                                    >
                                      {isFirstColumn ? (
                                        "TOTAL ALL"
                                      ) : (
                                        <FormattedNumberCell
                                          value={totalAll[column.dataIndex]}
                                          format={column.format}
                                          metric={column.dataIndex}
                                        />
                                      )}
                                    </Table.Summary.Cell>
                                  </>
                                );
                              }
                            })}
                          </Table.Summary.Row>
                          <Table.Summary.Row>
                            <>
                              {/* <Table.Summary.Cell
                            key={Math.random()}
                          ></Table.Summary.Cell> */}
                            </>
                            {columns.map((column, columnIndex) => {
                              const isFirstColumn = columnIndex === 0;
                              if (column?.children) {
                                // Handle grouped columns
                                return (
                                  <React.Fragment
                                    key={columnIndex}
                                    className={`${
                                      column.dataIndex
                                    }_total_filter ${
                                      isFirstColumn ? "fixed-footer" : ""
                                    }`}
                                  >
                                    {isFirstColumn ? (
                                      <Table.Summary.Cell index={columnIndex}>
                                        TOTAL FILTERED
                                      </Table.Summary.Cell>
                                    ) : (
                                      column.children.map(
                                        (childColumn, childIndex) => (
                                          <Table.Summary.Cell key={childIndex}>
                                            <FormattedNumberCell
                                              value={
                                                totalRow[childColumn.dataIndex]
                                              }
                                              format={childColumn.format}
                                              metric={childColumn.dataIndex}
                                            />
                                          </Table.Summary.Cell>
                                        )
                                      )
                                    )}
                                  </React.Fragment>
                                );
                              } else {
                                return (
                                  <>
                                    <Table.Summary.Cell
                                      key={columnIndex}
                                      className={`${
                                        column.dataIndex
                                      }_total_filter ${
                                        isFirstColumn ? "fixed-footer" : ""
                                      }`}
                                    >
                                      {isFirstColumn ? (
                                        "TOTAL FILTERED"
                                      ) : (
                                        <FormattedNumberCell
                                          value={totalRow[column.dataIndex]}
                                          format={column.format}
                                          metric={column.dataIndex}
                                        />
                                      )}
                                    </Table.Summary.Cell>
                                  </>
                                );
                              }
                            })}
                          </Table.Summary.Row>
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </>
              );
            }}

            // summary={getAllTotal}
          />
        )}
        {insight && insight?.length > 0 ? (
          <div className="insights">
            <h4>Insights</h4>
            <ul>
              {insight.map((data) => (
                <li>{HtmlParser(data)}</li>
              ))}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
      <CustomTableBottomComponents
        table_bottom_link={table_bottom_link}
        account_id={props?.account_id}
        client_id={props?.client_id}
        all_input_data={props?.all_input_data}
        product_ads_performance_bottom_link={
          product_ads_performance_bottom_link
        }
        subscribedAccounts={subscribedAccounts}
      ></CustomTableBottomComponents>
      {searching == "true" ? (
        searchPlaceholder == "PAGE URL" ? (
          <div className="semrush-action-buttons">
            <Button type="primary" size="default" onClick={submitKeyword}>
              SUBMIT FOR KEYWORD RESEARCH
            </Button>

            <a
              href={"/keyword_research_log/" + props?.account_id}
              target="_self"
            >
              KEYWORD RESEARCH LOG
            </a>

            <LPPModal
              account_id={props?.account_id}
              client_id={props?.client_id}
            />
          </div>
        ) : (
          <div className="semrush-action-buttons">
            <Button type="primary" size="default" onClick={addToCampaigns}>
              Add to Campaigns
            </Button>

            <Button type="primary" size="default" onClick={addToAdGroups}>
              Add to Ad Groups
            </Button>
          </div>
        )
      ) : (
        ""
      )}
      {negativeSearchTermCandidatesBtn == "true" ? (
        <div className="semrush-action-buttons">
          <a
            href={"/reports/v2/view/negative_keywords/" + props?.account_id}
            target="_self"
          >
            All Negative Search Terms Candidates
          </a>
          <Modal
            className="table-tooltip-modal"
            title="NEGATIVE KEYWORDS LOGIC"
            visible={isSharePopoverVisible}
            onOk={null}
            okButtonProps={{ style: { display: "none" } }}
            onCancel={handleCancel}
            cancelText="Close"
          >
            <div dangerouslySetInnerHTML={{ __html: config["htmlText"] }} />
          </Modal>
          <Tooltip trigger="click" onClick={showModal}>
            <span
              className="table-header-tooltip"
              style={{ color: "#8dc441", cursor: "pointer" }}
            >
              <FaInfoCircle />
            </span>
          </Tooltip>
        </div>
      ) : (
        ""
      )}
      {/* Filter modal */}
      <Modal
        title="FILTER COLUMN"
        visible={isModalOpen}
        onCancel={handleFilterModelCancel}
        onOk={form.submit}
        className="datatable-filter-modal"
        width={700}
        footer={null}
      >
        <Form
          form={form}
          name="form_modal"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <div className="filter-form-options">
            <Form.Item
              name="filter_column"
              label="Field :"
              // initialValue={filterVal.current}
            >
              <Select
                showSearch
                placeholder="Select a column"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {tableColumns.length > 0 &&
                  tableColumns?.map((column) => {
                    if (column?.children) {
                      return column.children.map((col) => (
                        <Option value={col.dataIndex} id={col.dataIndex}>
                          {col.title}
                        </Option>
                      ));
                    } else {
                      return (
                        <Option value={column.dataIndex} id={column.dataIndex}>
                          {column.title}
                        </Option>
                      );
                    }
                  })}
              </Select>
            </Form.Item>

            <Form.Item name="filter_condition" label="Type :">
              <Select placeholder="Select a type" optionFilterProp="children">
                {format == undefined ? (
                  <>
                    <Option value="equal">Equal to</Option>
                    <Option value="not_equal">Not Equal to</Option>
                    <Option value="like">Contains</Option>
                    <Option value="not_like">Does not contains</Option>
                  </>
                ) : (
                  <>
                    {" "}
                    <Option value="equal">Equal to</Option>
                    <Option value="less">Less than</Option>
                    <Option value="less_equal">Less than or Equal to</Option>
                    <Option value="greater">Greater than</Option>
                    <Option value="greater_equal">
                      Greater than or equal to
                    </Option>
                    <Option value="not_equal">Not Equal to</Option>
                    <Option value="like">Contains</Option>
                    <Option value="not_like">Does not contains</Option>
                  </>
                )}
              </Select>
            </Form.Item>

            <Form.Item name="filter_value" label="Value :">
              <Input placeholder="value to filter" />
            </Form.Item>

            <Form.Item name="format_value" hidden>
              <Input placeholder="format" hidden="hidden" />
            </Form.Item>
          </div>
          <div className="filter-form-buttons">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Filter
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="button"
                onClick={clearFilterForm}
                danger
              >
                Clear Filter
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>

      {/* Show/hide modal */}
      <Modal
        title="Show Hide Column Modal"
        visible={isShowHideModalOpen}
        onCancel={handleCancelShowHideModal}
        onOk={handleShowHideModalSubmit}
        className="datatable-toggle-modal"
        width={450}
        footer={[
          <Button key="close" onClick={handleCancelShowHideModal}>
            Close
          </Button>,
        ]}
      >
        <div className="toggle-form-options">
          <List>
            {tableColumns?.map((column) =>
              column?.children ? (
                column.children.map((col) => (
                  <List.Item key={col.dataIndex}>
                    <Checkbox
                      disabled={disabled}
                      onChange={(e) => onChange(e, col.dataIndex, column)}
                    >
                      {col.title}
                    </Checkbox>
                  </List.Item>
                ))
              ) : (
                <List.Item key={column.dataIndex}>
                  <Checkbox
                    disabled={disabled}
                    onChange={(e) => onChange(e, column.dataIndex)}
                  >
                    {column.title}
                  </Checkbox>
                </List.Item>
              )
            )}
          </List>
        </div>
      </Modal>
    </>
  );
});
export default DataTable;
