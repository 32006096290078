import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const PerformanceMaxInsightsReport = (props, text) => {
  let cellClass = "";
  let color = "";
  let recordData = props?.record;
  let blockId = props?.extraParams?.block?.block_id;

  if (blockId == 734) {
    if (
      props?.column?.dataIndex == "product" &&
      recordData?.only_curr_ten != ""
    ) {
      if (recordData?.only_curr_ten == "spend") {
        color = "#008bff";
      } else if (recordData?.only_curr_ten == "revenue") {
        color = "orange";
      } else if (recordData?.only_curr_ten == "both") {
      }
      return (
        <>
          <span className={cellClass}>
            <strong style={{ color: color }}>
              <FormattedNumberCell
                value={props?.value}
                format={props?.column?.format}
                metric={props?.column?.dataIndex}
              />
            </strong>
          </span>
        </>
      );
    } else {
      return (
        <>
          <span className={cellClass}>
            <FormattedNumberCell
              value={props?.value}
              format={props?.column?.format}
              metric={props?.column?.dataIndex}
            />
          </span>
        </>
      );
    }
  } else if (blockId == 869) {
    let record_data = props?.record;
    const asset_types = [
      "landscape_image",
      "square_image",
      "portrait_image",
      "landscape_logo",
      "square_logo",
      "video",
      "text",
      "other",
    ];

    let cellClass = "";
    if (asset_types.includes(props.column.dataIndex)) {
      if (props?.value === 0) {
        cellClass = "light-red-column";
      } else if (props?.value === 1) {
        cellClass = "light-yellow-column";
      } else if (props?.value > 1) {
        cellClass = "light-green-column";
      } else if (props?.value == "-") {
        cellClass = "";
      }
    }

    const hasArrayChildren = Array.isArray(record_data?.children);

    if (hasArrayChildren) {
      return (
        <span className={""}>
          <FormattedNumberCell
            value={props.value}
            format={props.column.format}
          />
        </span>
      );
    } else {
      return (
        <strong>
          <span className={cellClass}>
            <FormattedNumberCell
              value={props.value}
              format={props.column.format}
            />
          </span>
        </strong>
      );
    }
  } else {
    return (
      <>
        <span className={cellClass}>
          <FormattedNumberCell
            value={props.value}
            format={props.column.format}
            metric={props?.column?.dataIndex}
          />
        </span>
      </>
    );
  }
};

export default PerformanceMaxInsightsReport;
